.matches-calendar .nav-link {
    color: white;
}

.matches-calendar .nav-link.active,
.matches-calendar .nav-link:hover {
    opacity: 1;
    transition: 300ms;
    border-bottom: 3px solid var(--red);
}

.live-matches strong::before {
    content: "\2022";
    color: red;
    font-size: 16px;
    padding-inline-end: 8px;
    animation: flicker 0.5s infinite;
}

@keyframes flicker {
    0%{opacity: 1}
    50%{opacity: 0.4}
    100%{opacity: 1}
}