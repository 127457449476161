.ask-mido img {
  border: 1px solid var(--tealish-green);
}

.ask-mido textarea {
  border: 1px solid var(--tealish-green);
}

.ask-mido .btn.bg-active-color {
  width: 120px;
}
