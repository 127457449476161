/* *************************************** */
/* ********  START VIDEO CARD  *********** */
/* *************************************** */

.videosCardContainer .play-btn {
  top: 25%;
  padding: 5px 13px;
  right: 45%;
  cursor: pointer;
  font-size: 20px;
  color: var(--tealish-green);
  background-color: var(--white);
}

.videosCardContainer .card{
  background-color: #001D36;
}

.videosCardContainer .card-body .card-text{
  color: #AAC7E0;
  font-size: 0.7rem !important;
}

.videosCardContainer .card-body{
  color: var(--white);
}

.videosCardContainer .card-img,
.card-img-top {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
  cursor: pointer;
  min-height: 200px;
  max-height: 200px;
  padding: 0px !important;
}

.react-responsive-modal-container .react-responsive-modal-modal {
  background:unset;
  box-shadow: unset;
}

.react-responsive-modal-container .react-responsive-modal-modal div{
  color: var(--white);
  border-radius: 10px;
  background-color: var(--cyan-blue);
}

.react-responsive-modal-container .react-responsive-modal-modal img{
  border-radius: 20px;
}

.react-responsive-modal-modal .react-responsive-modal-closeButton {
  display: none;
}

.card-video-wraper{
  background-color: var(--cyan-blue-90-opacity);
  margin: 7px;
  border-radius: 5px;
}

/* *************************************** */
/* ********  END VIDEO CARD  *********** */
/* *************************************** */
