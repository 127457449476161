.groups__standingWrapper {
    border: 3px solid white;
    border-radius: 15px;
    padding: 16px;
    background-color: #191919;
}

.groups__standing thead tr th {
    font-size: 1.1rem;
}

.groups__standing tbody tr td {
    font-size: 1rem;
}

.groups__standing thead tr th,
.groups__standing tbody tr td {
    text-align: center;
    border-bottom: 1px dashed white;
    padding: 8px;
}

.groups__standing tbody tr:last-of-type td {
    border: none;
}

.groups__standing thead tr th:first-of-type,
.groups__standing tbody tr td:first-of-type {
    text-align: start;
}

.groups__standing tbody tr {
    border-right: 3px solid var(--red);
}

@media (max-width:575.98px) {
    .groups__standing thead tr th {
        font-size: var(--font-size-xs);
    }

    .groups__standing tbody tr td {
        font-size: 11px;
    }
}