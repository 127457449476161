/* *************************************** */
/* ********  START WORKOUT MOBILE  ******* */
/* *************************************** */
.workOutMobi .player-wrapper,
.workOutMobi ,
.workOutMobi {
  border-radius: unset;
}

.react-player__preview{
  background-color:var(--black-pearl) ;
}

.workOutMobi .card-header {
  background: unset;
  border-bottom: unset;
}

.workOutMobi .card h6,
.workOutMobi .card-header h6{
  color:var(--tealish-green) 
}

.workOutMobi .card p{
  color: var(--regal-blue);
}

.workOutMobi video {
  display: block;
  border-radius: 5px;
}
.workOutMobi .card-body {
  padding: unset;
}

.workOutMobi .play-btn {
  top: unset;
  right: unset;
  background-color: var(--tealish-green);
}
/* *************************************** */
/* ********  END WORKOUT MOBILE  ******* */
/* *************************************** */
/* *************************************** */
