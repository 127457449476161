/* *************************************** */
/* ***  START FAVORTIE AND DONE ICONS  *** */
/* *************************************** */
.favContainer {
  cursor: pointer;
}

.iconContainer svg,
.workOutMobi svg {
  color: var(--color-primary-dark);
}

.mobi-arrow:lang(en) {
  margin-left: auto;
}

.mobi-arrow:lang(ar) {
  margin-right: auto;
}

.favContainer:lang(en),
.doneContainer:lang(ar) {
  margin-right: auto;
}

.favContainer:lang(en),
.doneContainer:lang(ar) {
  margin-left: auto;
}

.favContainer .spinner-border,
.doneContainer .spinner-border {
  width: 16px;
  height: 16px;
}

/* *************************************** */
/* ***  END FAVORTIE AND DONE ICONS  ***** */
/* *************************************** */
