/* ********  START ASK WEB  *********** */
/* *************************************** */
.askWeb {
  color: var(--hamilton-white);
}

.askWeb img {
  border: 3px solid var(--tealish-green);
}

.askWeb h5{
  color: var(--tealish-green);
}

.askWeb a{
  color: var(--tealish-green);
}

.askWeb button{
  background-color: var(--tealish-green);
}

.askWeb button:hover{
  background-color:var(--teal);
}

/* *************************************** */
/* ********  END ASK WEB  *********** */
/* *************************************** */
