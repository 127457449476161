.match-page{
    overflow-x: hidden;
}


.live-match_info{
    background: url('../../assets/matchBackground.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    position: relative;
    z-index: 2;
}
@media (max-width:567px){
    .live-match-team-img{
        width: 50px;
    }
}
.img-gradient-overlay{
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
    opacity: .8;
    background: #000000;
    background: -webkit-linear-gradient(bottom, #000000, #202020);
    background: -moz-linear-gradient(bottom, #000000, #222222);
    background: linear-gradient(to top, #000000, #252525);
}
.live-match_info__cont{
    height: 80%;
    z-index: 2;
}
.live-match_info__min {
    background-color:#E8253D;
    margin: auto;
    border-radius: 4px;
}

.match-page .nav-pills .nav-item.show .nav-link, .match-page .nav-pills .nav-link.active {
    background-color: var(--black) !important;
    color: var(--tertiary-color) !important;
    border: 1px solid  var(--black) !important;
}

.match-page .nav-pills .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    background-color:var(--tertiary-color) !important;
    color:  var(--black) !important; 
    border: 1px solid  var(--tertiary-color) !important;
    margin: auto 5px;
}
.match-page .nav-link {
    display: block;
    padding: .25rem 1rem;
}

.match-page .nav-pills{
    display: flex;
    justify-content: center;
    position: relative;
    bottom:15px
}
        /* // Extra small devices (portrait phones, less than 576px) */
        @media (max-width: 575.98px) {
            .match-page    .nav {
            
                flex-wrap: inherit !important;
            }
          }
  
          /* // Small devices (landscape phones, 576px and up) */
          @media (min-width: 576px) and (max-width: 767.98px) {
            .match-page    .nav {
            
                flex-wrap: inherit !important;
            }
          }