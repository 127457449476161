/* *************************************** */
/* ********  START ARTICLE CARD  *********** */
/* *************************************** */

.articlesCardContainer{
  color:var(--white)
}

.articlesCardContainer img {
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
  height: 100%;
}

.articlesCardContainer .card{
  background-color: #001D36
}

.articlesCardContainer .no-gutters,
.articlesCardContainer .card {
  cursor: pointer;
  min-height: 200px;
}
.articlesCardContainer .card-footer {
  color: #AAC7E0;
  background-color: unset;
  border-top: none;
}

/* *************************************** */
/* ********  END ARTICLE CARD  *********** */
/* *************************************** */
