.matchs-carousel {
    width: 100%;
    background-color: var(--charcoal);
    border-radius: 5px;
}

/* .matchs-carousel .slick-slider {
    direction: inherit;
} */

.match-slide>div:first-of-type {
    border-color: var(--red) !important;
}

.slick-slider>button>i,
.slick-slider>button>i {
    color: var(--red) !important;
}

/* .matchs-carousel .slick-track{
    transform: translate3d(0px, 0px, 0px) !important;
    width: 100% !important;
    background-color: var(--black);
} */

.match-slide:hover {
    opacity: .8;
    cursor: pointer;
}

/* @media(max-width:1000px){
    .matchs-carousel .slick-track{
        transform: unset !important;
        width:unset !important;
        background-color: var(--black);
    }
} */

.slick-initialized .slick-slide {
    padding: 0px;
    margin: 0px;
}