.season-dropdown .dropdown-menu{
    height: fit-content;
    overflow: auto;
}

.season-dropdown .dropdown-menu .dropdown-item{
    color: var(--medium-grey);
}

.season-dropdown .dropdown-menu .dropdown-item:hover{
    color: var(--red);
}