
.fitness-page h3 {
    font-weight: 700;
    font-size: clamp(16px,2vw,34px);
}

.fitness-page video {
    border-radius: 8%;
}

.fitness-start-btn {
    border:none;
    padding: 15px;
    border-radius: 6px;
    font-weight: 700;
    color: var(--white);
    font-size: clamp(16px,2vw,24px);
    width: 100%;
}

.fitness-page img {
    width: clamp(240px,40%,40%);
    border-radius: 10px;
}