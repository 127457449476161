.away-team-formation{
    position: relative;
    height: 50%;

}
.home-team-formation{
    position: relative;
    height: 50%;
}

.home-players{

    height: 100%;
}
.away-players{
    height:100%;


}

.home-players-mini{

    height: 90%;
}


.away-team-formation-mini{
    height: 100%;
    position: relative;
   
}



.away-players-mini{
    height: 90%;
    
}



.home-team-formation-mini{
    height: 100%;
    position: relative;
}


.goalkeeper-mini{
    transform: rotate(180deg)!important
}

