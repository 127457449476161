body {
    --black: #000000;
    --charcoal: #323232;
    --heavy-metal: #2d2d2d;
    --medium-grey: #7c7c7a;
    --black-half-opacity: rgba(0, 0, 0, 0.5);
    --black-90-opacity: rgba(0, 0, 0, 0.9);
    --platinum: #e6e6dc;
    --hamilton-white: #f1f1f1;
    --white: #fff;

    --pinkish-red: #5a0d1e;
    --red: #e00800;
    --red-half-opacity: #e0070044;

    --tealish-green: #62D86E;
    --tealish-green-dark: #12b723;

    --black-pearl: #001D36;
    --regal-blue: #002a4d;
    --cyan-blue: #013181;
    --cyan-blue-half-opacity: #004f907c;
    --cyan-blue-90-opacity: #004f90dc;


    /* Al Ahly Page Colors */
    --harsh-red: #c21921dd;
    --dim-gray: #af9797cb;
    --gray-half-opacity: #af979780;
    --soft-black: #273642;

}