.react-responsive-modal-root .user-img {
  width: 100px;
  height: 100px;
}

.react-responsive-modal-overlay, .react-responsive-modal-container, .react-responsive-modal-modal { 
  animation-fill-mode: forwards!important; 
}

@media (max-width: 767.98px) {
  .react-responsive-modal-root .user-img {
    width: 80px;
    height: 80px;
  }
}
