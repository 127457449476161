.news-section .news-card {
    height: 200px;
    margin-top: 5px;
}

.news-card:hover {
    transform: scale(1.02);
    transition: transform 2s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    filter: brightness(110%);
}

.news-card:hover .news-card-description {
    bottom: 0px !important;
    width: 100% !important;
}

.news-card .news-card-description span {
    font-size: clamp(12px,1.5vw,16px) !important;

}
.news-card .news-card-description p {
    font-size: clamp(12px,1.5vw,18px) !important;
}

.news-card-description .date {
    color: var(--red);
}

.news-section .news-card img {
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 3px;
    border:1px solid var(--red) ;
}

.news-section .slick-slider .slick-slide .news-card {
    width: 270px;
    height: 190px;
    position: relative;
}

.news-section .slick-slider .slick-slide .news-card .news-card-description {
    width: 270px;
}

.news-section .slick-slider .slick-slide .news-card img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border: none;
    object-position: top;
    object-fit: cover;
}

.slick-slide {
    align-items: center;
    justify-content: center;
    width: 300px;

}


.play-icon-wrapper {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(50%, -50%);
}

.play-icon {
    font-size: var(--font-size-md);
    background: rgba(0, 0, 0, .7);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.play-icon .fa-play {
    color: white;
    z-index: 2;
}

.play-icon:hover .fa-play {
    color: var(--red);
}

.news-section .news-card .news-card-description {
    position: absolute;
    bottom:5px;
    left: 50%;
    word-spacing: 1.5px;
    width: 95%;
    font-size: var(--font-size-xs);
    transform: translateX(-50%);
}

.news-section .slick-slider .slick-slide .news-card .news-card-description {
    bottom: 4px;
    word-spacing: 1.5px;
}

.news-card-carousel {
    height: 415px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

.news-card-carousel img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border: none;
    object-position: top;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid var(--red);   
}

.news-card-carousel .news-card-description {
    position: absolute;
    bottom: 0px;
    width: 100%;
    line-height: 1.8;
    word-spacing: 1.5px;
}

.slick-prev:before,
.slick-next:before {
    display: none
}

.news-card-carousel .news-carousel-img {
    object-fit: cover;
    position: center;
}

.load__moreButton {
    width: 94.5px;
    height: 36.5px;
}

.load__moreButton .spinner-border,
.spinner-border {
    width: 20px;
    height: 20px;
}

@media(max-width:500px) {
    .news-section .news-card {
        height: 150px;
        margin-top: 5px;
    }

    .news-section .news-card .news-card-description {
        font-size: 8px;
    }

    .news-section .news-card .news-card-description span span {
        font-size: 6px;
    }

    .news-section .news-card img {
        height: 150px;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    .news-card-carousel {
        height: 300px;
    }
}