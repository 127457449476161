.predict__resultForm {
    display: grid;
    grid-template-columns: auto auto;
}

.select__wrapper {
    position: relative;
}

.predict__resultForm option,
.predict__resultForm select {
    cursor: pointer;
}

.predict__resultForm img {
    width: 60px;
    height: 60px;
}

.predict__resultForm select {
    padding-block: 8px;
    padding-inline: 30px 80px;
    border-radius: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.predict__resultForm .select__wrapper::after {
    content: "<";
    position: absolute;
    font-size: 30px;
    color: #999999;
    top: 0;
    left: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
    /* padding-block-end: 20px; */
}


.button__wrapper {
    grid-column: 1 / span 2;
    margin-block: 130px 100px;
}

.button__wrapper>button {
    border-radius: 6px;
    padding: 7px 50px;
    background-color: white;
    color: var(--black);
}

@media (max-width:575.98px) {
    .predict__resultForm {
        grid-template-columns: auto;
        row-gap: 30px;
    }

    .button__wrapper {
        grid-column: 1;
        margin-block: 100px;
    }
}