.choose-plan h3 {
    color: var(--white);
}

.choose-plan .card h6 {
    color: var(--charcoal);
}

.choose-plan nav.nav.nav-tabs {
    width: 150px;
    margin: auto;
    justify-content: center;
    border: 2px solid var(--red);
    border-radius: 50px;
}

.choose-plan nav.nav.nav-tabs .nav-item.nav-link.active {
    border-radius: 30px;
    background-color: var(--white) !important;
    color: var(--charcoal) !important;
}
