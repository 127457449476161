.match-the-player .panel1-imgs{
    max-width: 600px;
    max-height: 450px;
}

.match-the-player .panel1-imgs img {
    border-radius: 50%;
    padding: 10px;
    width: 80px;
}

.match-the-player .panel1-imgs img:first-child,
.match-the-player .panel1-imgs img:last-child{
    background-color: var(--white);
}

.match-the-player .player-wraper li,
.match-the-player .numbers-wraper li{
    position: relative;
}

.match-the-player .player-wraper li{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 5px;
}

.match-the-player .player-wraper li img {
    margin-inline: 10px;
    width: 100%;
    max-width: 100px;
    border-radius: 50%;
}

.match-the-player .player-wraper li div {
    position: absolute;
    left: 50%;
    top: 50%;
    transition: all 300ms;
}

.match-the-player .player-wraper li:hover div {
    scale:1.1;
}

.match-the-player .player-wraper li div img {
    width: 70px;
    background-color: var(--white);
    padding: 5px;
    border-radius: 50%;
    border: 1px solid var(--tealish-green);
}

.match-the-player .player-wraper li div span {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    color: var(--tealish-green);
    font-size:20px;
}

.match-the-player .numbers-wraper li {
    margin-inline: 5px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 300ms;
}

.match-the-player .numbers-wraper li:hover{
    scale: 1.1;
}

.match-the-player .numbers-wraper li img {
    width: 100%;
    max-width: 100px;
    min-width: 68px;
    background-color: var(--white);
    padding: 10px;
    border-radius: 50%;
}

.match-the-player .numbers-wraper li span {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    color: var(--tealish-green);
    font-size: 30px;
}

@media (max-width:465px) {
    .match-the-player .player-wraper li div span {
        font-size:14px;
    }

    .match-the-player .player-wraper li div img {
        width: 50px;
    }    

    .match-the-player .numbers-wraper li span {
        top:30%;
        font-size: 20px;
    }
    
}