.select__Form {
    width: 25%;
}

.gallery img{
    border: 2px solid var(--charcoal);
    border-radius: 5px;
    object-fit: cover;
    object-position: top;
}

@media (max-width:991.98px) {
    .select__Form {
        width: 50%;
    }
}

@media (max-width:575.98px) {
    .select__Form {
        width: 100%;
    }
}