.play-btn {
  padding: 5px 15px;
  font-size: 25px;
  border-radius: 30px;
  background-color: #ffffff;
  color: var(--color-primary-dark);
  position: absolute;
}

.play-btn::before {
  font-family: "FontAwesome";
  content: "\f04b";
}

.play-btn {
  top: 45%;
  right: 45%;
  cursor: pointer;
}

.player-wrapper {
  width: 100%;
}

video {
  -o-object-fit: cover;
  object-fit: cover;
}

.player-wrapper,
.react-player__preview,
.subscription-image {
  min-height: 300px;
}

video {
  border-radius: 10px;
}

.react-responsive-modal-modal {
  width: 50%;
}

.nutrition-btn-grp a{
  font-size: 16px !important;
  border: 2px solid #00315A;
  background-color: #00315A;
  border-radius: 5px !important;
}

.nutrition-btn-grp a:hover{
  border: 2px solid #00315A;
  background-color: #04457a;
}
  
@media (max-width: 1199.98px) {
  .react-responsive-modal-modal {
    width: 100%;
  }
}