.playstation-form {
  display: flex;
  width: 30%;
  min-width: 200px;
  flex-direction: column;
  gap: 10px;
}

.playstation-form input {
  margin-bottom: 0;
  padding-bottom: 0;
  height: 40px;
  border-radius: 6px;
  text-align: center;
}

.playstation-form span {
  color: rgb(210, 46, 46);
  padding: 5px;
  padding-top: 0;
  margin-top: 0;
  font-size: var(--font-size-md);
}

.playstation__formButton {
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
}