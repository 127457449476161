.fan-club-skeleton-container{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-content: stretch;
    justify-content: center !important;
}

.row.fan-club-skeleton-row{
   width: 20vw;
}

@media (max-width:1199.98px) {
    .row.fan-club-skeleton-row{
        width: 80vw;
     }
}