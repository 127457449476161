.Tournament-details-header-container {
  height: 400px;
}
.Tournament-details-header {
  background-image: url("../../assets/tournamentBackground.jpg");
  background-size: 100% 411px;
  filter: blur(4px);
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  height: 400px;
  z-index: 2;
}

.Tournament-details-header-desc {
  filter: blur(0);
  position: relative;
  height: 200px;
  bottom: 70%;
  z-index: 2;
}

.Tournament-details-header-desc img {
  height: 150px;
}
.seasons-dropdown {
  position: absolute;
  top: 0px;
}

.descriptions-container {
  text-align: center;
  color: var(--white);
  padding: 1em;
}

.descriptions-container > .games-page-title {
  padding: 1em;
  margin: 1em;
}

.descriptions-container button {
  height: 32px;
  width: 125px;
  padding: 2px;
  color: #5a0d1e;
}

@media (max-width: 500px) {
  .Tournament-details-header-container {
    height: 300px;
  }
  .Tournament-details-header {
    background-image: url("../../assets/tournamentBackground.jpg");
    background-size: 100% 411px;
    filter: blur(4px);
    background-position: top;
    position: relative;
    background-repeat: no-repeat;
    height: 300px;
  }
  body:lang(en) .Tournament-details-header-desc {
    height: 150px;
    width: 170px;
    right: -30%;
    bottom: 80%;
  }
  .Tournament-details-header-desc {
    height: 150px;
    width: 170px;
    right: 30%;
    bottom: 80%;
  }
  .Tournament-details-header-desc img {
    height: 100%;
    width: 100%;
  }
}
