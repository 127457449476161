
.tournament-standing.table-borderless th {
    padding: 17px ;
    vertical-align: top;
    border-top:none !important ;
}

.tournament-standing .form-check-input{
    background-color: var(--charcoal);
    border-color: var(--medium-grey);
    font-size: 15px;
    cursor: pointer;
    
}


@media (max-width:500px) {
    .tournament-standing.table-borderless img{
        width: 20px;
    }
    .tournament-standing.table-borderless td, .tournament-standing.table-borderless th {
        padding: 5px ;
        vertical-align: top;
        border-top:none !important ;
    }

}


@keyframes heartBeat-x {
    0% { transform: scaleX(0)}
    10% { transform: scaleX(1)}
    20% { transform: scaleX(0)}
    30% { transform: scaleX(1)}
    40% { transform: scaleX(0)}
}