.all-competitions img{
    width: 25px;
    height: 25px;
}
.competition-card{
    border-radius: 16px !important;
    color: var(--white);
}
.competition-card:hover{
    background-color: var(--red);
    color: var(--white);
}
.img-rounded-circle{
    width:60px;
    height: 60px;
    background-color: white;
    border-radius: 50%;
}
.all-competions-header{
    background-image: url(../../assets/allCompetions.png);
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position:top;
}


.competitions-overlay{
    background-color: rgba(0,0,0,.3);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}