.Team-details-header-container {
    height: 350px;
    position: relative;
}

.overlay {
    position: absolute;
    background-color: var(--black-half-opacity);
    z-index: 1;
    top: 0;
    right: 0;
    width: 100%;
    height: 101%;
}

.Team-details-header {
    /* background-image: url('../../assets/teamBackground.png'); */
    background-size: 120% 350px;
    filter: blur(4px);
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;

    z-index: 1;
}

.Team-details-header-desc {
    filter: blur(0);
    position: relative;
    height: 200px;
    top: 15%;
    z-index: 3;
}

.Team-details-header-desc img {
    height: 150px;
}

.Team-details-header-champions {
    filter: blur(0);
    position: absolute;
    bottom: 0;
    right: 180px;
    z-index: 2;
    background-color: var(--black-half-opacity);
}

.worldCup-styles .Team-details-header-champions {
    background-color: #e0070044;
}

.Team-details-header-champions img {
    width: 50px;
    height: 50px;
}

.Team-details-header-champions .champions-times {
    position: absolute;
    top: 89%;
    border-radius: 50%;
    width: 15px;
    background-color: var(--charcoal);
    font-size: var(--font-size-xs);
}

.worldCup-styles .Team-details-header-champions .champions-times {
    background-color: #e0070044;
}

body:lang(en) .Team-details-header-market {
    position: absolute;
    bottom: 0%;
    left: 180px;
    z-index: 2;
    background-color: var(--black-half-opacity);

}

.Team-details-header-market {
    position: absolute;
    bottom: 0%;
    left: 180px;
    z-index: 2;
    background-color: var(--black-half-opacity);
    ;
}

.seasons-dropdown {
    position: absolute;
    top: 0px
}

@media(max-width:650px) {
    .Team-details-header-container {
        height: 300px;
    }

    .Team-details-header {
        /* background-image: url('../../assets/teamBackground.png'); */
        background-size: 120% 411px;
        filter: blur(4px);
        background-position: top;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        background-repeat: no-repeat;
        height: 100%;

    }

    body:lang(en) .Team-details-header-desc {
        height: 100px;
        width: 100px;
        right: -40%;
        position: relative;
    }

    .Team-details-header-desc {
        height: 100px;
        width: 100px;
        position: relative;
        right: 50%;
        transform: translateX(50%);
    }

    .Team-details-header-desc img {
        height: 100%;
        width: 100%;
    }

    body:lang(en) .Team-details-header-champions {
        filter: blur(0);
        position: absolute;
        bottom: 0%;
        right: 0px;
        z-index: 2;
        background-color: var(--black-half-opacity);
    }

    .Team-details-header-champions {
        filter: blur(0);
        position: absolute;
        bottom: 0%;
        right: 0px;
        z-index: 2;
        background-color: var(--black-half-opacity);
    }

    .Team-details-header-champions img {
        width: 30px;
        height: 30px;
    }

    body:lang(en) .Team-details-header-market {
        position: absolute;
        bottom: 0%;
        left: 0px;
        z-index: 2;
        background-color: var(--black-half-opacity);

    }

    .Team-details-header-market {
        position: absolute;
        bottom: 0%;
        left: 0px;
        z-index: 2;
        background-color: var(--black-half-opacity);

    }
}