:root {
  --border-radius-35: 35%;
}

.border-radius-35 {
  border-radius: var(--border-radius-35);
  -webkit-border-radius: var(--border-radius-35);
  -moz-border-radius: var(--border-radius-35);
  -ms-border-radius: var(--border-radius-35);
  -o-border-radius: var(--border-radius-35);
}

.border-radius-50 {
  border-radius: 50%;
}

hr {
  height: 5px;
  color: #707070;
  margin-top: 50px;
}

.home-page {
  text-align: start;
  overflow: hidden;
  overflow-y: hidden;
  margin-bottom: 15px;
}

.etisalat-border {
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
}

.active-color {
  color: var(--red);
}

.bg-black-color {
  background-color: var(--black);
}

.bg-black-color-opacity {
  background-color: var(--black-half-opacity);
}

.bg-light-black-color {
  background-color: var(--charcoal);
}

.white-color {
  color: #fff;
}

.grey-color {
  color: var(--medium-grey);
}

.bg-active-color {
  background-color: var(--red);
}

.tertiary-color {
  color: var(--tertiary-color);
}

* {
  box-sizing: border-box;
}

html,
body {
  /* background-color: var(--black); */
  background: transparent linear-gradient(180deg, #162D51 0%, #052F73 50%, #03183A 100%) 0% 0% no-repeat;
  padding: 0;
  margin: 0;
  color: var(--color-tertiary-xlight);
  font-size: var(--font-size-xm);
  text-align: start;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--charcoal);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: var(--medium-grey);
  outline: 1px solid slategrey;
}

ul {
  list-style: none outside none;
  text-decoration: none;
  padding: unset;
}

a {
  color: var(--black);
  text-decoration: none;
}

a:hover {
  color: var(--secondary-color);
  text-decoration: none;
}

.nav.nav-tabs {
  background-color: var(--charcoal) !important;
  color: var(--medium-grey) !important;
  border-bottom: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: var(--medium-grey);
  border: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: white;
  background: none !important;
  border: none !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  color: white;
  border: none !important;
}

.dropdown .dropdown-toggle.btn.btn-secondary {
  background-color: var(--heavy-metal);
  border: none;
  color: var(--medium-grey);
}

.dropdown .dropdown-menu {
  background-color: var(--heavy-metal);
}


.table-clickable-row-animated{
  background: linear-gradient(to right,var(--red),var(--red)) 100% 100%/0 100% no-repeat;
  transition: all 0.4s ease-out !important;

}

.table-clickable-row-animated:hover{
  transform: scale(1.01) !important;
  background-position: 100% 0%;
  background-size: 100% 100%;
}

.table-clickable-row:hover {
  transition: all 0.4s ease-out !important;
  background-color: var(--red) !important;
  cursor: pointer;
  color: white;
  transform: scale(1.01) !important;
}

.clickable {
  cursor: pointer;
}

.table-clickable-row-no-animation:hover {
  cursor: pointer;
  color: white;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.text-cut-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-height: 2;
}

.text-cut-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.5;
}

.text-cut-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 1.5;
}

.text-cut-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-height: 1.5;
}

button:focus,
.form-control:focus {
  outline: unset;
  border-color: unset;
  box-shadow: unset;
}

/* //// Button Style  */

.cust-btn-primary {
  background-color: var(--red);
  border: unset;
  border-radius: unset;
  font-size: var(--font-size-md);
}

.cust-btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--red);
  opacity: 0.8;
  border-color: transparent;
}

/* react matches calendar styles */
.react-calendar__tile .react-calendar__month-view__days__day .react-calendar__month-view__days__day--neighboringMonth {
  color: white;
}

body .react-calendar {
  width: 350px;
  max-width: 100%;
  background: var(--charcoal) !important;
  line-height: 1.125em;
  color: var(--medium-grey);
  border: none !important;
  direction: initial;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  color: var(--medium-grey);
  border: 1px solid transparent !important;
}

.react-calendar__tile--active {
  background-color: var(--red) !important;
  outline: 3px solid var(--red) !important;
  outline-offset: -3px;
  border-radius: 50% !important;
  color: white !important;
}

.react-calendar__navigation button[disabled],
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__tile:hover,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--red) !important;
  outline: 3px solid var(--red);
  outline-offset: -3px;
  border-radius: 50%;
  color: white !important;
}

button.react-calendar__tile--now {
  background: transparent;
  color: white;
  border-radius: 50%;
  border: 2px solid var(--red) !important;
}

.react-calendar__tile.react-calendar__century-view__decades__decade:hover,
.react-calendar__tile.react-calendar__century-view__decades__decade:enabled:hover,
.react-calendar__tile.react-calendar__century-view__decades__decade:enabled:focus,
.react-calendar__navigation button[disabled],
.react-calendar__tile.react-calendar__decade-view__years__year:enabled:hover,
.react-calendar__tile.react-calendar__decade-view__years__year:enabled:focus,
.react-calendar__tile.react-calendar__year-view__months__month:hover,
.react-calendar__tile.react-calendar__year-view__months__month:enabled:hover,
.react-calendar__tile.react-calendar__year-view__months__month:enabled:focus,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  border-radius: 0 !important;
}

.react-calendar__tile--hasActive {
  background-color: var(--red) !important;
  color: white !important;
}

.react-calendar__tile--now.react-calendar__year-view__months__month,
.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
  border-radius: 0;
}

.vs-button button:enabled {
  /* animation: heartBeat; */
  animation: heartBeat;
  animation-duration: 1500ms;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.5);
  }

  20% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.5);
  }

  40% {
    transform: scale(1);
  }
}

/* @keyframes heartBeat {
  0% 
  { 
      transform: scale(1)
  }
  10%
  { 
      transform: scale(1.5)
  }
  20%
   { 
       transform: scale(1)
   }
  30% 
  { 
      transform: scale(1.5)
  }
  40% 
  { 
      transform: scale(1)scaleX(0)
  }
} */

/* snackbar styles */
.MuiSnackbarContent-root {
  background-color: transparent !important;
}



.slick-initialized .slick-slide {
  padding-inline: 10px;
}

@media (max-width: 600px) {
  .slick-initialized .slick-slide {
    padding-inline: 3px;
  }
}

.slick-slide:not(.slick-active) {
  left: 0 !important;
}

body:lang(ar) .start-carousel-at-right,
body:lang(ar) .start-carousel-at-right .slick-track>div {
  transform: scaleX(-1);
}

.slick-track {
  align-items: flex-start !important;
}

.swipe-animation {
  background-image: linear-gradient(to right,
      var(--red),
      var(--red) 50%,
      #000 50%);
  background-size: 200% 100%;
  /* background-position: -100%; */
  display: inline-block;
  padding: 5px 0;
  position: relative;
  background-clip: "text";
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.swipe-animation:hover {
  background-position: 200%;
}

tr.row-hover:hover {
  transform: scale(1.1);
  background-color: var(--red) !important;
  transition: all 0.5s;
  cursor: pointer;
}

/* Workaround to fix Sass hot-reload */
html>body>iframe[style*="position: fixed"] {
  display: none;
}

button.btn.focus,
button.btn:focus {
  box-shadow: 0 0 0 0.2rem var(--red-half-opacity);
}

/*##############################################################*/
/*############### START OF WORLD CUP PAGES STYLES ##############*/
/*##############################################################*/

.worldCup-styles {
  background: linear-gradient(#7b1329cc, #7b1329cc),
    url("../src/assets/games/fan-club-logo.svg");
  background-color: #7b132a;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: 50% 47%;
}

.worldCup-styles .Tournament-user-points-conatiner {
  position: absolute;
  right: 5%;
  height: fit-content;
  z-index: 3;
  padding: 8px;
  background-color: #ad8790;
  color: #30050f;
  border-radius: 6px;
  margin-top: 20px;
}

.worldCup-styles .Tournament-user-points-conatiner p {
  padding: 0;
  margin: 0;
  font-size: var(--font-size-md);
  font-weight: 700;
}

.worldCup-styles .games-page-title {
  background-color: #470917b3;
  border-radius: 6px;
}

.worldCup-styles .nav.nav-tabs {
  background-color: #3d0915 !important;
  color: white !important;
}

.worldCup-styles .react-calendar button,
.worldCup-styles .nav-link,
.worldCup-styles .nav-item {
  color: white;
}

.worldCup-styles .dropdown-toggle.btn.btn-secondary {
  background-color: #3d0915;
  color: white;
}

.worldCup-styles .bg-black-color {
  background-color: #3d0915 !important;
  color: white !important;
  border-radius: 6px;
}

.worldCup-styles .table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #30050f;
}

.worldCup-styles .navbar.navbar-dark.navbar-expand-lg {
  background-color: var(--black) !important;
}

.worldCup-styles .bg-light-black-color.text-center.text-light {
  background-color: #3d0915;
  border-radius: 6px;
}

.worldCup-styles .react-calendar__navigation button:enabled:hover,
.worldCup-styles .react-calendar__tile:hover,
.worldCup-styles .react-calendar__tile:enabled:hover,
.worldCup-styles button.react-calendar__tile--now,
.worldCup-styles .bg-light-black-color:hover,
.worldCup-styles .table-clickable-row:hover,
.worldCup-styles .a.text-light:focus,
.worldCup-styles a.text-light:hover {
  background-color: #804754 !important;
}

.worldCup-styles .slick-carousel.start-carousel-at-right,
.worldCup-styles .text-light.dropdown-menu.show,
.worldCup-styles .slick-slider,
.worldCup-styles .slick-list,
.worldCup-styles .bg-light-black-color,
.worldCup-styles .comming__matchesDate,
.worldCup-styles .predict__andWin--ButtonWrapper {
  background-color: #3d0915;
}

.worldCup-styles .comming__matchesDate {
  border-radius: 6px 6px 0 0;
}

.worldCup-styles .predict__andWin--ButtonWrapper {
  border-radius: 0 0 6px 6px;
}

.worldCup-styles .bg-black-color {
  margin-top: 5px;
}

.worldCup-styles .groups__standingWrapper {
  margin: 1em;
  background-color: #3d0915;
}

.worldCup-styles .nav-tabs .nav-link:hover,
.worldCup-styles .nav-tabs .nav-link.active {
  color: #eba8a8 !important;
  border-bottom: 2px solid #eba8a8 !important;
}

/* .worldCup-styles .bg-black-color.white-color.fs-xs {
  min-width: fit-content;
} */

.worldCup-styles .bg-active-color {
  background-color: #eba8a8;
  color: #3d0915 !important;
}

.worldCup-styles .bg-active-color:hover,
.worldCup-styles .bg-active-color:disabled {
  background-color: #e1cdcd;
  color: #3d0915 !important;
}

.predict__andWin--Button.text-capitalize,
.worldCup-styles .text-capitalize.btn.btn-primary {
  color: #3d0915;
  border: none;
}

.worldCup-styles .text-capitalize.btn.btn-primary:hover {
  border: none;
}

.worldCup-styles .tournaments-matches-list .match-card {
  background-color: #5e1224 !important;
}

.worldCup-styles tr.row-hover:hover {
  background-color: #804754 !important
}

/*##############################################################*/
/*############### END OF WORLD CUP PAGES STYLES ##############*/
/*##############################################################*/

@media (max-width: 992px) {
  .responsive-table {
    display: block;
    /* overflow: auto; */
    font-size: var(--font-size-xs);
  }
}

@media (max-width: 768px) {
  .responsive-table {
    
  }
}

@media (max-width: 600px) {
  .nav.nav-tabs {
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;
  }
}

.top__assistsTable thead tr,
.top__assistsTable tbody tr,
.top__scorersTable thead tr,
.top__scorersTable tbody tr {
  display: grid;
  grid-template-columns: 2fr auto 1fr;
  align-items: center;
}