.player-wrapper video {
  max-height: 500px;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: unset;
}

.video-details-title{
  width:97%;
  margin: auto;
  background-color: var(--white);
  color:var(--black);
  border-radius:5px;  
}

.video-details-title h4{
  color:var(--tealish-green)
}