.match-header {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.match-header .match-timer >div{
    scale: 1.3;
}

.match-header-score {
    height: 45px;
    width: 45px;
    text-align: center;
    font-size: 24px !important;
}

.match-header-score:first-child {
    margin-inline-end: 3rem;    
}

.match-header-score:last-child {
    margin-inline-start: 3rem;    
}

.match-header-team-info {
    width: 90%;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, .8);
    text-align: center;
    border-radius: 18px;
}

.match-header-team-img {
    object-fit: cover;
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.parse__date {
    padding-inline-start: .5rem;
}

.manager__name img {
    border-radius: 50%;
}

.referee-name-container img {
    filter: invert();
}

.referee-name,
.parse__date,
.parse__time,
div.manager__name,
.stadium__name {
    font-size: clamp(6px, 2vw, 18px);
}

@media (max-width:725px) {

    .time-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:567px) {

    .match-header-team-img {
        width: 60px;
        height: 60px;
        object-fit: contain;
    }

    .match-header-score {
        height: 30px;
        width: 30px;
    }

    .match-header-score:first-child {
        margin-inline-end: 0;    
    }
    
    .match-header-score:last-child {
        margin-inline-start: 0;    
    }

    .match-header-info {
        font-size: 15px;
    }

    .match-header-team-info {
        width: 100%;
    }

    .time-container,
    .date-container,
    .stadium-name-container,
    .referee-name-container {
        margin-inline: 0px;
        padding-inline: 0px;
    }

    .match-header-team-info {
        padding: 20px !important
    }
}


@media (max-width:475px) {

    .match-header-score {
        height: 45px;
        width: 45px;
        text-align: center;
        font-size: 16px !important;
    }

    .manager__name {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .team1Scorers img {
        width: 20px;
        height: 20px;
        margin-inline: 5px !important;
    }

    .scorer {
        padding-inline: 0px !important;
        font-size: 2.6vw !important;
    }

    .match-header .match-timer >div{
        scale: 0.8;
    }

}