.reusableBtn.animation::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  width: 8px;
  height: 100%;
  background-image: linear-gradient(to right, var(--black), var(--black) 70%, var(--black) 50%);
  animation: swipe-put 0.2s;
}

.slick-slider .reusableBtn.animation::before{
  background-image: linear-gradient(to right, var(--heavy-metal), var(--heavy-metal) 70%, var(--heavy-metal) 50%);
}

.reusableBtn.animation:hover::before {
  animation: swipe-in 0.3s;
}

@keyframes swipe-in {
  0% {
    left: 0;
    opacity: 1;
  }
  100% {
    left: 60%;
    opacity: 1;
  }
}

@keyframes swipe-put {
  0% {
    left: 60%;
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    left: 0;
    opacity: 0;
  }
}