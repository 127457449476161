.wrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 30px;
}

.teams__wrapper {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 12px;
}

.team {
    column-gap: 20px;
    background-color: rgba(255, 255, 255, .15);
    border-radius: 6px;
    padding: 12px 30px;
    margin-block-end: 12px;
    justify-content: center;
    position: relative;
}

.fav__iconWrapper {
    position: absolute;
    top: 5px;
    right: 10px;
}

.fav__icon,
.fav__iconBold {
    cursor: pointer;
}

.team__info {
    align-items: center;
    justify-content: center;
}

.team__info img {
    width: 30px;
    height: 30px;
}

.team__info figcaption {
    font-size: var(--font-size-sm);
}

.world__cupLogo {
    /* width: 250px; */
    height: 600px;
    object-fit: cover;
    justify-self: center;
    align-self: center;
}


@media (max-width:1199.98px) {
    .wrapper {
        grid-template-columns: 1fr;
        column-gap: 0;
        row-gap: 30px;
    }

    .team {
        padding: 12px 10px;
    }

    .world__cupLogo {
        /* width: 250px; */
        height: 450px;
    }

}

@media (max-width:575.98px) {
    .world__cupLogo {
        /* width: 150px; */
        height: 300px;
    }
}