.tournament__knockoutsWrapper>div::before,
.tournament__knockoutsWrapper>div::after {
    z-index: -1;
}

.main__wrapper {
    height: calc(100% - 43.19px);
}

.match {
    width: 100%;
    border: 1px solid white;
    border-radius: 5px;
    padding: 20px 15px;
    margin-block: 30px;
    margin-inline: auto;
}

.clickable{
    cursor: pointer;
}

.match img,
.match .skeleton__teamFlag {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.match img+span {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.match>div {
    height: 60px;
}

.match>div:nth-of-type(2) {
    flex-grow: 0;
    font-size: var(--font-size-xs);
}

.match__resultWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
    position: relative;
}

.match__resultWrapper::before, .match__resultWrapper::after {
    content: "";
    position: absolute;
    left: -15px;
    width: 15px;
    height: 50%;
    border: 1px solid white;
    border-right: 0;
    z-index: -1 !important;
}

.round__3_4 .match__resultWrapper::before, .round__3_4 .match__resultWrapper::after {
    content: none;
}

.round__4 .match__resultWrapper::before,
.round__4 .match__resultWrapper::after {
    z-index: -2 !important;
}


.match__resultWrapper::after {
    left: -30px;
    height: 2px;
    border: unset;
    border-bottom: 2px solid white;
}

.round__2 .match__resultWrapper::before,
.round__2 .match__resultWrapper::after {
    right: -30px;
}

.round__2 .match__resultWrapper::after {
    right: -15px;
}

.round__4 .match__resultWrapper::before,
.round__4 .match__resultWrapper::after {
    all: unset;
}

.round__2 .match__resultWrapper .match {
    border: 2px solid white;
    border-radius: 10px;
}

.round__2 {
    position: relative;
}

.round__3_4 {
    position: absolute;
    bottom: 9px;
}

.round__2 .cup__icon {
    color: yellow;
    font-size: var(--font-size-xxl);
}
.round__3_4 .cup__icon {
    color: #CD7F32;
}

/* ---------------------------- Start Media Query --------------------------- */

@media(max-width:1399.98px) {

    .match,
    .match__resultWrapper,
    .match__resultWrapper::before,
    .match__resultWrapper::after,
    .match__resultWrapper::after,
    .round__2 .match__resultWrapper::before,
    .round__2 .match__resultWrapper::after,
    .round__2 .match__resultWrapper::after,
    .round__4 .match__resultWrapper::before,
    .round__4 .match__resultWrapper::after {
        all: unset;
    }

    .tournament__knockoutsWrapper h2 {
        display: none;
    }

    .tournament__knockoutsWrapper {
        flex-direction: column;
        position: relative;
        height: 1350px;
    }

    .round__16,
    .round__8,
    .round__4,
    .round__2 {
        position: static;
    }
    
    .round__3_4 {
        bottom: 40px;
        left: 0;
        width: 100%;
    }
    
    .round__3_4 .match__resultWrapper::before, .round__3_4 .match__resultWrapper::after {
        content: none !important;
    }

    .match {
        justify-content: space-around !important;
    }

    .match img,
    .match .skeleton__teamFlag {
        width: 30px;
        height: 30px;
    }

    .round__16::before,
    .round__16::after {
        content: "";
        position: absolute;
        top: 107px;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 140px;
        background-color: white;
    }

    .round__16::after {
        top: unset;
        bottom: 235px;
        height: 138px;
    }

    .round__16 .main__wrapper {
        display: flex;
        flex-flow: row !important;
    }

    .round__16 .match__resultWrapper {
        flex-direction: column;
        row-gap: 30px;
    }

    .round__16 .main__wrapper::before,
    .round__16 .main__wrapper::after {
        content: "";
        position: absolute;
        width: 29px;
        left: 50%;
        border-top: 1px solid white;
        top: calc(-49px + 58px);
        transform: translateX(-50%);
        z-index: 0 !important;
        height: 99px;
    }

    .round__16 .main__wrapper::before {
        border: unset;
        border-bottom: 1px solid white;
    }

    .round__16 .main__wrapper::after {
        top: unset;
        bottom: calc(78px + 58px);
    }

    .round__16 .match__resultWrapper:nth-of-type(1),
    .round__16 .match__resultWrapper:nth-of-type(2) {
        position: absolute;
        width: calc(50% - 40px);
        top: 0;
        left: 15px;
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
    }

    .round__16 .match__resultWrapper:nth-of-type(1) {
        left: unset;
        right: 15px;
    }

    .round__16 .match__resultWrapper:nth-of-type(3),
    .round__16 .match__resultWrapper:nth-of-type(4) {
        position: absolute;
        bottom: 126px;
        width: calc(50% - 40px);
        left: 15px;
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
    }

    .round__16 .match__resultWrapper:nth-of-type(3) {
        left: unset;
        right: 15px;
    }

    .round__16 .match__resultWrapper .match {
        border: 1px solid white;
        border-radius: 5px;
        padding: 15px 10px;
    }

    .round__16 .match__resultWrapper:nth-of-type(1)::after,
    .round__16 .match__resultWrapper:nth-of-type(2)::before,
    .round__16 .match__resultWrapper:nth-of-type(3)::after,
    .round__16 .match__resultWrapper:nth-of-type(4)::before {
        content: "";
        position: absolute;
        height: 50%;
        width: 10px;
        border: 1px solid white;
        border-right: 0;
        top: 50%;
        transform: translateY(-50%);
        left: -10px;
    }

    .round__16 .match__resultWrapper:nth-of-type(2)::before,
    .round__16 .match__resultWrapper:nth-of-type(4)::before {
        border: 1px solid white;
        border-left: 0;
        left: unset;
        right: -10px;
    }

    .round__8 .match__resultWrapper:nth-of-type(1),
    .round__4 .match__resultWrapper:nth-of-type(1) {
        position: absolute;
        top: 267px;
        width: calc(100% - 30px);
        left: 15px;
    }

    .round__8 .match__resultWrapper:nth-of-type(1)::before,
    .round__8 .match__resultWrapper:nth-of-type(1)::after,
    .round__8 .match__resultWrapper:nth-of-type(2)::before,
    .round__8 .match__resultWrapper:nth-of-type(2)::after {
        content: "";
        position: absolute;
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
        top: -20px;
        height: 20px;
        border: 1px solid white;
        border-bottom: 0;
    }

    .round__8 .match__resultWrapper:nth-of-type(1)::after,
    .round__8 .match__resultWrapper:nth-of-type(2)::after {
        top: unset;
        bottom: -21px;
        border: 1px solid white;
        border-top: 0;
    }

    .round__4 .match__resultWrapper:nth-of-type(1) {
        top: 424px;
    }

    .round__8 .match__resultWrapper:nth-of-type(2),
    .round__4 .match__resultWrapper:nth-of-type(2) {
        position: absolute;
        bottom: 394px;
        width: calc(100% - 30px);
        left: 15px;
    }

    .round__4 .match__resultWrapper:nth-of-type(2) {
        bottom: 551px;
    }

    .round__8 .match__resultWrapper:nth-of-type(1),
    .round__8 .match__resultWrapper:nth-of-type(2) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 50px;
    }

    .round__4 .match__resultWrapper:nth-of-type(1)::before,
    .round__4 .match__resultWrapper:nth-of-type(2)::after {
        content: "";
        position: absolute;
        top: -54px;
        left: 50%;
        transform: translateX(-50%);
        height: 54px;
        border-right: 2px solid white;
    }

    .round__4 .match__resultWrapper:nth-of-type(2)::after {
        top: unset;
        bottom: -55px;
        height: 55px;
    }

    .round__8 .match__resultWrapper:nth-of-type(1) .match,
    .round__8 .match__resultWrapper:nth-of-type(2) .match {
        width: 100%;
    }

    .round__8 .match__resultWrapper:nth-of-type(1) .match,
    .round__8 .match__resultWrapper:nth-of-type(2) .match,
    .round__4 .match__resultWrapper:nth-of-type(1) .match,
    .round__4 .match__resultWrapper:nth-of-type(2) .match {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid white;
        border-radius: 5px;
        padding: 10px 5px;
    }

    .round__2 .match__resultWrapper {
        position: absolute;
        top: 45.3%;
        width: calc(100% - 34px);
        left: 15px;
        transform: translateY(-50%);
        display: flex;
        border: 2px solid white;
        border-radius: 10px;
        justify-content: center
    }

    .round__2 .match__resultWrapper .match {
        border: unset;
    }

    .round__2 .match__resultWrapper:nth-of-type(1)::before,
    .round__2 .match__resultWrapper:nth-of-type(1)::after {
        content: "";
        position: absolute;
        border-right: 2px solid white;
        height: 60px;
        top: -61px;
        left: 50%;
        transform: translateX(-50%);
    }

    .round__2 .match__resultWrapper:nth-of-type(1)::after {
        top: unset;
        bottom: -61px;
    }

    .round__2 .match__resultWrapper:nth-of-type(1) .match {
        flex-grow: 1;
        padding: 15px 5px;
    }

    .cup__icon {
        font-size: 30px;
    }

}

@media(max-width:575.98px) {

    .match img,
    .match .skeleton__teamFlag {
        height: 25px;
        width: 25px;
    }

    .match>div {
        padding-inline: 0 !important;
        justify-content: center !important;
    }


    .result__score {
        margin-inline: 0 !important;
    }

    .round__16 .match img+span,
    .round__8 .match img+span {
        font-size: var(--font-size-xxs);
    }

    .round__8 .match__resultWrapper:nth-of-type(1) {
        top: 250px;
    }

    .round__4 .match__resultWrapper:nth-of-type(1) {
        top: 390px;
    }

    .round__8 .match__resultWrapper:nth-of-type(2) {
        bottom: 450px;
    }

    .round__4 .match__resultWrapper:nth-of-type(2) {
        bottom: 590px;
    }

    .round__16::before,
    .round__16::after {
        bottom: 296px;
        height: 133px;
    }

    .round__16::before {
        top: 97px !important;
    }

    .round__16 .main__wrapper::before {
        top: calc(-49px + 47px);
        border: unset;
        border-bottom: 1px solid white;
    }

    .round__16 .main__wrapper::after {
        bottom: calc(150px + 47px);
    }

    .round__16 .match__resultWrapper .match {
        padding: 10px 5px;
    }

    .round__16 .match__resultWrapper:nth-of-type(3),
    .round__16 .match__resultWrapper:nth-of-type(4) {
        bottom: 198px;
    }

    .round__4 .match__resultWrapper:nth-of-type(1)::before {
        top: -38px;
    }

    .round__4 .match__resultWrapper:nth-of-type(2)::after {
        bottom: -38px;
    }

    .round__2 .match__resultWrapper:nth-of-type(1)::before,
    .round__2 .match__resultWrapper:nth-of-type(1)::after {
        top: -58px;
        height: 56px;
    }

    .round__2 .match__resultWrapper:nth-of-type(1)::after {
        top: unset;
        bottom: -58px;
    }
    .round__2 .match__resultWrapper {
        top: 42.6%;
    }
    
    .round__3_4 {
        bottom: 125px;
    }

    .round__4 .match__resultWrapper:nth-of-type(1)::before,
    .round__4 .match__resultWrapper:nth-of-type(2)::after {
        height: 38px;
    }
}

html body.twist-ui .match__resultWrapper,
.match__resultWrapper::before,
.match__resultWrapper::after,
.match,
.round__16 .main__wrapper::before,
.round__16 .main__wrapper::after {
    background-color: var(--cyan-blue);
}

html body .match__resultWrapper,
.match__resultWrapper::before,
.match__resultWrapper::after,
.match,
.round__16 .main__wrapper::before,
.round__16 .main__wrapper::after {
    background-color: var(--black);
}

html body .worldCup-styles .match__resultWrapper,
.match__resultWrapper::after,
.match,
.round__16 .main__wrapper::before,
.round__16 .main__wrapper::after {
    background-color: transparent;
}

html body .worldCup-styles .match__resultWrapper::before,
html body .worldCup-styles .match__resultWrapper::after,
html body .worldCup-styles .round__16 .main__wrapper::before,
html body .worldCup-styles .round__16 .main__wrapper::after {
    background-color: transparent;
    z-index: auto !important
}

html body .worldCup-styles .tournament__knockoutsWrapper>div::before,
html body .worldCup-styles .tournament__knockoutsWrapper>div::after {
    z-index: auto !important
}