.navbar_2{
  width: 120% ;
  margin: 0px ;
  max-width: 100% ;
  padding: 0px ;
}

.navbar_2 .navbar-nav:lang(en) {
  margin-left: auto;
}

.navbar_2 .active {
  color: var(--tealish-green) !important;
}

.navbar_2 .navbar-nav:lang(ar) {
  margin-right: auto;
}

body .navbar_2 .navbar {
  background-color: var(--white);
  padding : 0px 10px
}

.navbar_2 .nav-link {
  color: var(--black) ! ;
}

.navbar_2 .navbar-logo {
  width: 140px;
}

.navbar_2 .navbar-brand {
  margin-left: 0px;
}

.navbar_2 .dropdown-item:hover,
.navbar_2 a:hover {
  color: var(--tealish-green) !important;
}

.navbar_2 .dropdown-item{
  color: var(--black) !important;
  background-color: var(--white);
}

.navbar_2 .dropdown-menu{
  background-color: var(--white) ;
}

.sc-bxivhb {
  text-align: center;
}

.navbar .nav-item .nav-link{
  color: var(--black) !important;
}

.navbar_2 .navbar-collapse.collapse{
  max-width: 76%;
}

.navbar_2 .navbar-collapse.collapse .navbar-nav{
  margin-inline: 0px;
}

.navbar_2 .navbar-collapse.collapse .navbar-nav a{
  font-weight:800 ;
}

@media (max-width: 991.98px){
  .navbar_2 .navbar-collapse.collapsing .navbar-nav a,
  .navbar_2 .navbar-collapse.collapse.show .navbar-nav a{
    font-size: 20px !important;
    font-weight:500 ;

  }
}