.navbar-brand {
    font-size: 1.9rem;
    color: #ABABAB
}

.navbar {
    background-color: var(--black);
    position: relative;
    /* top: 0;
    right: 0;
    left: 0;
    z-index: 1; */
}

.navbar .nav-item .nav-link {
    color: #ABABAB
}

.dropdown-menu {
    background-color: var(--black);
    z-index: 3
}

.navbar .dropdown-menu .dropdown-item {
    color: #ABABAB
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.dropdown-toggle::after {
    color: var(--red);
}

.nav-link,
.nav-item {
    border-bottom: 2px solid transparent;
    transition: 300ms;
}

.navbar.navbar-dark ul.navbar-nav a.nav-link {
    color: white;
}

.nav-link:hover,
.nav-link.active {
    opacity: 1;
    border-bottom: 3px solid var(--red);
    color: var(--red) !important;
}

/* .nav-link::before {
    transition: 300ms;
    height: 5px;
    content: "";
    position: absolute;
    background-color: var(--red);
  }

.nav-link-fade-up::before {
    width: 4.5%;
    bottom: 5px;
    opacity: 0;
  }
  
  .nav-link-fade-up:hover::before {
    bottom: 10px;
    opacity: 1;
  } */