.player-details-header-container {
    height: 411px;
    position: relative;
}

.player-details-header-container.without-team-bg {
    height: 200px;
}

.overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, .2);
    z-index: 1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.player-details-header {
    background-size: 120% 411px;
    filter: blur(4px);
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: 1;
}

.without-team-bg .player-details-header {
    background: radial-gradient(circle farthest-side at 80% 50%, white, white 15%, #7f7f7f 41%, #606060);
    filter: none;
}

body:lang(en) .player-details-header-info {
    position: absolute;
    bottom: 0;
    left: 535px;
    z-index: 2;
}

.player-details-header-info {
    position: absolute;
    bottom: 0;
    right: 535px;
    z-index: 2;
}

.without-team-bg .player-details-header-info {
    text-align: center;
}

.player-details-header-info strong {
    color: var(--platinum);
}

.without-team-bg .player-details-header-info strong {
    color: var(--black);
}

.player-details-header-info img {
    width: 120px;
}

.without-team-bg .player-details-header-info img {
    width: 90px;
}

.player-details-header-info-num {
    font-size: 60px;
    color: var(--black);
}

.without-team-bg .player-details-header-info-num {
    font-size: var(--font-size-xxl);
}

.without-team-bg .player-name {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 30px !important;
}

body:lang(en) .player-details-img {
    position: absolute;
    bottom: 0;
    left: 200px;
    height: 100%;

}

.player-details-img {
    position: absolute;
    bottom: 0;
    right: 200px;
    height: 100%;
    z-index: 2;

}

.player-details-img img {
    height: 100%;
    width: 394.5px;
}

.without-team-bg .player-details-img img {
    width: 200px;
}

body:lang(en) .player-details-header-champions-market {
    filter: blur(0);
    position: absolute;
    bottom: 0;
    right: 200px;
    left: unset;
    z-index: 2;
}

.player-details-header-champions-market {
    filter: blur(0);
    position: absolute;
    bottom: 0;
    left: 200px;
    z-index: 2;
    align-items: flex-end;
    height: 100%;
}

body:lang(en) .player-details-header-champions {
    filter: blur(0);
    bottom: 0;
    left: unset;
    z-index: 2;
    background-color: var(--black-half-opacity);
    color: var(--platinum);
}

.player-details-header-champions {
    filter: blur(0);
    bottom: 0;
    z-index: 2;
    background-color: var(--black-half-opacity);
    color: var(--platinum);
}

.without-team-bg .player-details-header-champions {
    background-color: transparent;
    color: var(--black);
    height: 100%;
}

.player-details-header-champions img {
    width: 45px;
    height: 42px;
}


body:lang(en) .player-details-header-market {
    bottom: 0%;
    z-index: 2;
    left: unset;
    background-color: var(--black-half-opacity);
    color: var(--platinum);
}

.player-details-header-market {
    bottom: 0%;
    z-index: 2;
    background-color: var(--black-half-opacity);
    color: var(--platinum);
    height: fit-content;
}

.without-team-bg .player-details-header-market {
    bottom: 0%;
    z-index: 2;
    background-color: var(--black-half-opacity);
    color: var(--white);
    border-top-right-radius: 10%;
    border-top-left-radius: 10%;
}

.seasons-dropdown {
    position: absolute;
    top: 0px
}

@media(max-width:1400px) {
    .player-details-header-container {
        height: 300px;
    }

    body:lang(en) .player-details-img {
        height: 100%;
        width: 100%;
        left: 0px;
        right: unset;
        position: relative;
    }

    .player-details-img {
        height: 100%;
        width: 100%;
        position: relative;
        right: 0px;
        left: unset;
    }

    .player-details-img img {
        height: 100%;
        width: 200px;
        object-fit: cover;
        position: center;
    }

    body:lang(en) .player-details-header-info {
        left: 185px;
        right: unset;
    }

    .player-details-header-info {
        position: absolute;
        bottom: 0;
        right: 185px;
        left: unset;
        z-index: 2;
    }

    .without-team-bg .player-details-header-info {
        align-items: center;
        justify-content: flex-start;
    }

    .player-details-header-info img {
        width: 90px;
    }

    body:lang(en) .player-details-header-champions-market {
        filter: blur(0);
        position: absolute;
        bottom: 0%;
        right: 50px;
        z-index: 2;
        background-color: var(--black-half-opacity);
    }

    .player-details-header-champions-market {
        filter: blur(0);
        position: absolute;
        bottom: 0%;
        left: 60px;
        z-index: 2;
    }


}



@media (max-width: 900px) {
    .player-details-header-info {
        display: flex;
        flex-direction: column;
        padding-inline-start: 1.5rem;
        height: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .player-details-header-info-num {
        font-size: 30px !important;
        height: fit-content;
    }

    .player-details-name strong {
        font-size: 30px !important;
        height: fit-content;
    }

    .without-team-bg .player-details-header-info {
        align-items: center;
        justify-content: flex-start;
    }

    .player-details-current-team-img {
        width: 60px !important;
    }

    .player-details-header-market {
        padding: 5px;
    }
}

@media (max-width: 420px) {

    .player-details-header-info-num,
    .player-details-name strong {
        font-size: 20px !important;
    }

    .player-details-header-champions-market {
        left: 40px;
    }
}