/* *************************************** */
/* ********  START Rating   ************ */
/* *************************************** */

.widget-svg.hovered .star,
.widget-svg {
  fill: var(--color-primary-light) !important;
}

/* *************************************** */
/* ********  END Rating   ********** */
/* *************************************** */
