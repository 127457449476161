.full-search{
    position: relative;
}
.full-search-input{
  width: 100%;
}
.full-search-menu{
    position: absolute;
    top: 45px;
    overflow: scroll;
    max-height: 538px;
    z-index: 999;
    width: 310px;
    background-color: var(--black-half-opacity);
    overflow-x: hidden;
}

.full-search-menu::-webkit-scrollbar {
    width: .5em;
  }
   
.full-search-menu::-webkit-scrollbar-track {
    background-color: var(--charcoal);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  .full-search-menu::-webkit-scrollbar-thumb {
    background-color: var(--medium-grey);
    outline: 1px solid slategrey;
  }

  @media(max-width:500px){
    .full-search-input{
      width: 210px;
    }
    .full-search-menu{
      width: 220px;
    }
  }