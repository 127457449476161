.comparison {
    display: flex;
    flex-direction: column;
    margin-block-end: 20px;
    border-radius: 5px;
    height: 300px;
}

.comparison-title {
    color: var(--white);
    text-align: start;
    font-size: 18px;
}

.comparison-content-wrapper {
    display: flex;
    flex-grow: 1;
    background-image: url('../../assets/comparison.png');
    background-repeat: no-repeat;
    background-position: 50% 20%;
    background-size: cover;
    padding: 20px 25px;
    border-radius: 5px;
    justify-content: space-between;
}

.comparison-description {
    font-size: 14px;
    color: var(--white);
}

.comparison-action {
    display: flex;
    align-items: center;
    background-color: var(--white);
    padding: 12px 10px;
    border-radius: 6px;
    align-self: end;
    cursor: pointer;
    color:var(--black);
}

.comparison-action>span {
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold;
    padding-inline-start: 1rem;
}

.comparsion-logo{
    width: 150px;
    height: 150px;
    object-fit: cover;
}

@media (max-width:991.98px) {

    .comparison-content-wrapper {
        flex-direction: column;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        height: 260px;
    }

    .comparison-description {
        font-size: 12px;
        flex-grow: 1;
        text-align: justify;
    }

    .comparison-action {
        align-self: flex-end;
    }

    .comparison-action img {
        width: 25px;
        height: 25px;
    }

    .comparison-action>span {
        font-size: 12px;
    }
}