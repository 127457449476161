.table-borderless {
  width: 100% !important ;
  border-radius: 5px;
}
.table-borderless img {
  width: 35px;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border: none;
}
.table-borderless td,
.table-borderless th {
  padding: 7px;
  vertical-align: middle;
  border-top: none !important ;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: none;
  border-width: 0;
}

.GeneralStanding-title-btn-icon {
  font-size: 1.3rem;
  font-weight: 1000;
}

/* .table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
    border: none;
} */

@media (max-width: 500px) {
  .table-borderless img {
    object-fit: cover;
  }
  .table-borderless td,
  .table-borderless th {
    padding: 5px;
  }
}
