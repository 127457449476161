.choose-provider img{
    width: 180px;
}

.choose-provider button{
    border:none;
    padding: 30px;
    background-color: var(--white);
    border-radius: 10px;
}

.choose-provider div img{
    width: 120px;
    height: 110px;
    background-color: aliceblue;
}