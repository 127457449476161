/* *************************************** */
/* ********  START PROGRESS BAR   ******** */
/* *************************************** */

.progress {
  border-radius: unset;
}
.progress-bar {
  background-color: var(--tealish-green);
}

/* *************************************** */
/* ********  START  PROGRESS BAR  ******** */
/* *************************************** */
/* *************************************** */
/* ********  START WORKOUT WEB  *********** */
/* *************************************** */

.workOutWeb .break-li {
  cursor: default;
}

.workOutWeb .react-player__preview {
  position: absolute;
  height: 100%;
}

.workOutWeb .player-wrapper,
.workOutWeb .react-player__preview,
.workOutWeb .player-wrapper video,
.workOutWeb .subscription-image {
  min-height: 70vh;
  border-radius: unset;
  -o-object-fit: cover;
  object-fit: cover;
}

.playlist-container .list-group-item {
  background-color: var(--white);
  color: var(--regal-blue);
}

.playlist-container .list-group-item h6,
.playlist-container .list-group-item.rest h6{
  color: var(--tealish-green);
}

.playlist-container {
  height: 70vh;
  cursor: pointer;
  overflow-y: scroll;
}

.playlist-container li:hover:not(.break-li) h6,
.playlist-container li:hover:not(.break-li) p {
  color: var(--color-primary-light) !important;
}

.playlist-container .active {
  background: var(--color-primary-dark);
  border: unset;
}

.playlist-container .active svg {
  color: var(--color-primary-light);
}

.playlist-container li {
  z-index: 55;
}

.workOutWeb .play-btn {
  padding: 10px 25px;
  font-size: 38px;
  border-radius: 50px;
}

.ps__rail-y {
  z-index: 60;
}

/* *************************************** */
/* ********  END WORKOUT WEB  *********** */
/* *************************************** */
