.slick-carousel {
  background-color: var(--black);
  padding: 5px 0;
  border-top: 1px solid var(--red);
  border-bottom: 1px solid var(--red);
}

.slick-carousel img {
  width: 40px;
  height: 40px;
  margin: auto;
  object-fit: contain;
}

.slick-list {
  margin: 0 10px;
}

.slick-carousel img:hover {
  opacity: .8;
}

.slick-prev>i,
.slick-next>i {
  color: var(--red);
}

.slick-slider .slick-track {
  display: flex;
  align-items: center;
}