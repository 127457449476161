/* ********  START MOBILE HOME  ************* */
/* *************************************** */
.homeMobiContainer .card-body {
  padding: 15px;
  border-radius: 5px;
  border:5px solid #fff;
  transition: all 0.3s;
}

.homeMobiContainer .card-body:hover{
  border:5px solid #62D86E;
}


/* *************************************** */
/* ********  END MOBILE HOME  ************* */
/* *************************************** */
