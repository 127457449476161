.menu_btn {
  cursor: pointer;
}

.collapse_show {
  background-color: var(--black-90-opacity);
  top: 0;
  bottom: 0;
  width: 250px;
  position: fixed;
  /* right:0; */
  box-shadow: rgb(0 0 0 / 20%) -3px 3px 6px;
  z-index: 1;
  /* transform: translateX(0%); */
  overflow-x: scroll;
  transition: all ease-in-out 0.4s;
}

.MobileSideMenu ::-webkit-scrollbar {
  display: none;
}

body:lang(en) .right_arrow img {
  transform: rotatey(180deg);
}

.BiMenuAltRight {
  right: 100px;
  color: var(--red);
}

.MobileSideMenu {
  width: 100%;
  border: none;
  z-index: 2;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 10%);
}

li a,
.dropdown {
  font-size: 1.1em;
  color: white;
  letter-spacing: 0.5px;
  transition: all 300ms linear;
}

li a.active {
  opacity: 1;
  color: var(--red);
}

.in {
  right: 0;
  z-index: 16;
}

.out {
  right: -450px;
}

.BiMenuAltRight {
  position: relative;
  z-index: 1;
  width: 120px;
  height: 40px;
  border: none;
  border-radius: var(--border-radius-5) !important;
  outline: none;
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

.collapse_show p {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5em;
}

.collapse_show li {
  border-bottom: 1px solid var(--medium-grey);
  padding: 10px;
}

.other__sportsList {
  border-bottom: 1px solid var(--medium-grey);
}

.other__sportsList > li {
  border-bottom: 1px dashed var(--medium-grey);
}

.main__links > li {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
}

.nested__subLinks--wrapper {
  grid-column: 1 / span 2;
}

.nested__subLinks--wrapper > li {
  padding-inline-start: 15px;
  border-bottom: 1px dashed var(--medium-grey);
}

.nested__subLink--opened {
  transform: rotate(180deg);
}

.nested__subLinks--wrapper > li:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

@media (max-width: 575.98px) {
  body:lang(en) .in {
    left: 0;
  }

  body:lang(en) .out {
    left: -75%;
  }

  .BiMenuAltRight {
    right: 20px;
  }

  .collapse_show {
    width: 60%;
  }

  .in {
    right: 0;
  }

  .out {
    right: -75%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .BiMenuAltRight {
    right: -52px;
  }

  .collapse_show {
    width: 60%;
  }

  .in {
    right: 0;
  }

  .out {
    right: -75%;
  }
}

@media (min-width: 768px) and (max-width: 990.98px) {
  .BiMenuAltRight {
    right: -52px;
  }

  .collapse_show {
    width: 60%;
  }

  .in {
    right: 0;
  }

  .out {
    right: -75%;
  }
}
