.media_body_container .pickgradient {
  position: relative;
  display: inline-block;
}

.media_body_container .pickgradient:after {
  padding: 5px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  display: inline-block;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); IE6-9 */
}

.media_body_container img {
  width: 100%;
  max-height: 416px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
}

.media_body_container {
  position: relative;
}

.media_body_container .article_body {
  margin: 20px 0;
}

.media_body_container small {
  color: #b2b2b2;
}


.media_body_container .fa-link{
  color: #c0c0c0;
  transition: 0.3s;
}

.media_body_container .fa-twitter{
  color: #1DA1F2;
  transition: 0.3s;
}

.media_body_container .fa-facebook{
  color: #4267B2;
  transition: 0.3s;
}

.media_body_container .fa-link:hover{
  color: #ffffff;
}

.media_body_container .fa-twitter:hover{
  color: #6ac0f5;
}

.media_body_container .fa-facebook:hover{
  color: #6d84b2;
}


