.match-events {
    position: relative;
    font-weight: 400;
    line-height: 1.5em;
    color: var(--black);
}

.event-team-first {
    position: relative;
    width: 200px;
    left: 73px;
    white-space: nowrap;
    z-index: 100;

}

.event-team-first img {
    width: 25px;
}

.event-team-second {
    position: relative;
    width: 200px;
    right: 88px;
    white-space: nowrap;
    z-index: 2;

}

.event-team-second img {
    width: 25px;
}

.vl {
    border-left: 3px dotted white;
    /* border-left: 3px solid rgb(58, 88, 58, .5); */
    height: 100%;
    position: absolute;
    top: 0%;
    left: 48.5%;
    z-index: 1;
}

.event-team {
    background-color: var(--charcoal);
    position: relative;
    right: 6px;
    padding: 15px;
    z-index: 2;
}

.event-team:nth-of-type(2) {
    margin-bottom: 0 !important;
}

.event-team:last-of-type,
.event-team-first:last-of-type,
.event-team-second:last-of-type {
    margin-top: 0 !important;
}

.exchange__iconWrapper>.exchange__icon {
    font-size: 20px;
}

.exchange__iconWrapper>.exchange__icon:first-of-type {
    transform: rotate(180deg);
}