/* *************************************** */
/* ****  START USER IMAGE AND RATE ******* */
/* *************************************** */
.user-img {
  width: 40px;
  border-radius: 50px !important;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
}

/* *************************************** */
/* ****  END USER IMAGE AND RATE ******* */
/* *************************************** */
