.match-card .status-bar {
    min-width: 90px;
    border-radius: 10px;
}

.match-card .match-date {
    font-size:14px ;
    padding-block: 0.5rem;
}

.predict__andWin--ButtonWrapper {
    background-color: var(--charcoal);
}

.predict__andWin--Button {
    border: none;
    font-size: 15px;
    padding: 5px 8px;
    border-radius: 5px;
    transition: all .3s linear;
}

/* .predict__andWin--Button:hover {
    transform: scaleX(1.05) translateY(-3px);
} */

.match-card .match-carousel-rounded-white {
    /* background-color: white; */
    border-radius: 50%;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
}