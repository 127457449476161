@mixin on-circle($item-count, $circle-size, $item-size) {
  position: absolute;
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
  padding: 0;
  list-style: none;
  transform: rotatez(-90deg);

  >* {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: calc(-1 * ($item-size / 2));
    width: $item-size;
    height: $item-size;

    $angle: calc(360 / $item-count);
    $rot: 0;

    // @for $i from 0 through $item-count {
    //   &:nth-of-type(#{$i}) {
    //     // transform: translate($circle-size / 2);
    //     transform: rotate($rot * 1deg) translate($circle-size / 2);
    //     // transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
    //   }

    //   $rot: $rot +$angle;
    // }
  }
}

.radial-timer {
  @include on-circle($item-count: 90, $circle-size: 7.5em, $item-size: 1em);
  top: 8.5px;
  // border: solid 5px rgba($color: #eb1818, $alpha: .7);

  .radial-timer-event {
    display: block;
    max-width: 100%;
    color: transparent;

    transition: .15s;

    &:hover {
      filter: grayscale(0);
    }
  }
}

@media(max-width:767.98px) {
  .radial-timer {
    @include on-circle($item-count: 90, $circle-size: 9.7em, $item-size: 1.5em);
    top: 11.5px;
    // border: solid 5px rgba($color: #eb1818, $alpha: .7);

    .radial-timer-event {
      display: block;
      max-width: 100%;
      color: transparent;

      transition: .15s;

      &:hover {
        filter: grayscale(0);
      }
    }
  }
}