.banner-container{
    background-color: #00315A;
}

.banner-container h4{
    color: var(--tealish-green);
}

.banner-container p{
    color: var(--white);
}