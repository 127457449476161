/* *************************************** */
/* ********  START WEB HOME  ************* */
/* *************************************** */
.homeWebContainer .card,
.homeWebContainer .circle-icon {
  background: #E2ECF4;
  border-radius: 48px;
  cursor: pointer;
  border: 3px solid #62D86E ;
  transition: all 0.3s;
}

.homeWebContainer .card{
  border: 3px solid #E2ECF4 ;
}

.homeWebContainer .circle-icon {
  width: 100px;
  height: 100px;
  position: absolute;
  left: calc(50% - 50px);
  top: -50px;
  border-radius: 50%;
  z-index: 5;
  background: #FFFFFF;

}

.homeWebContainer .circle-icon svg {
  margin: 30px;
}

.homeWebContainer .card:hover .circle-icon,
.homeWebContainer .card:hover{
  transition: 0.4s;
  border: 3px solid #002748 ;
}

.homeWebContainer .circle-icon > img {
  color: var(--color-primary-dark);
  width: inherit;
  padding: 30px;
}
/* *************************************** */
/* ********  END WEB HOME  *************** */
/* *************************************** */
