.sideMenu {
    height: auto;
    width: 220px;
    background-color: var(--charcoal);
    z-index: 998;
    border-radius: 12px;
    position: fixed;

    top: 30%;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.sideMenu:lang(ar) {
    left: -162px;
}

.sideMenu:lang(en) {
    right: -162px;
}

.sideMenu>a {
    opacity: .5;
}

.sideMenu>a.active,
.sideMenu>a:hover {
    opacity: 1;
}

.table-clickable-row-no-animation .handball__image {
    border: 1px solid wheat;
    border-radius: 50%;
    background-color: wheat;
}

@media (max-width: 768px) {
    .sideMenu {
        bottom: 0px;
        left: 0px !important;
        top: auto;
        display: block;
        width: 100%;
        border-radius: 0;
        text-align: center;
    }
}