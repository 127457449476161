html body.twist-ui {
    --black: var(--cyan-blue);
    --black-half-opacity: var(--cyan-blue-half-opacity);
    --black-90-opacity: var(--cyan-blue-90-opacity);
    --charcoal: var(--regal-blue);
    --heavy-metal: var(--black-pearl);
    --medium-grey: var(--white);
    
    --red: var(--tealish-green);
    --red-half-opacity: var(--cyan-blue-half-opacity);
    --tealish-green-dark:var(--tealish-green);

    --light: "norsal-light";
    --regular: "norsal-regular";
    --bold: "norsal-extra-bold";
}

body.twist-ui .navbar {
    background-color: var(--black-pearl);
}


/* ################################################ */
/* ##########  Al Ahly Page Styles  ############### */
/* ################################################ */

html body.alAhly-page-styles{
    --black:var(--dim-gray);
    --charcoal:var(--dim-gray);
    --red:rgb(212, 184, 184);
    --black-half-opacity: var(--gray-half-opacity);
    --heavy-metal: var(--dim-gray);
    --bs-table-accent-bg:var(--dim-gray);
    --tealish-green:var(--harsh-red);

    background: linear-gradient(var(--harsh-red), var(--harsh-red)),
    url("../../assets/AlAhlyBG.png");
    background-color: var(--harsh-red);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
}

html body.alAhly-page-styles .react-loading-skeleton{
    background-color: var(--harsh-red)  ;
    background-image: linear-gradient( 90deg,var(--harsh-red),var(--medium-grey),var(--harsh-red))
}

html body.alAhly-page-styles .modal-content{
    background-color: var(--harsh-red);
}

html body.alAhly-page-styles .modal-content button{
    color: var(--gray);
}
