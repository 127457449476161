.games {
  margin-block: 4rem;
  text-align: center;
  color: var(--white);
  padding: 1em;
  background-image: url('../../assets/games/gamesPageBgImg-min.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 10px
}

.require-subscribtion-title {
  text-align: center;
  margin-block: 3rem;
  color: var(--tealish-green);
  cursor: pointer;
  font-size: 18px;
}

.games .main-description {
  position: relative;
}

.games .main-description .user-points {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  right: 2rem;
}

.games .main-description .user-points div {
  display: flex;
  gap: 5px;
  align-items: center;
  border: 2px solid var(--tealish-green);
  border-radius: 5px;
  padding: 5px;
  background-color: var(--charcoal);
}

.games .main-description h4 {
  font-weight: 700;
  margin-block-start: 2rem;
}

.games>.games-page-title {
  padding: 1em;
  margin: 1em;
}

.games button {
  color: #5a0d1e;
}

.container.games .game-card {
  padding: 5px;
  background-color: var(--hamilton-white);
  border-radius: 7px;
  overflow: hidden;
}

.container.games .game-card:hover{
  cursor: pointer;
}

.container.games .game-card:hover img {
  scale: 1.2;
}

.container.games .game-card:hover .title h6 {
  scale: 1.2;
}

.container.games .game-card img {
  height: 140px;
  width: 150px;
  max-width: 200px;
  border-radius: 10px;
  object-fit: cover;
  background-color: var(--charcoal);
  transition: scale 400ms;
  opacity: 0.7;
}

.container.games .game-card img.al-ahly {
  object-fit: contain;
}

.container.games .game-card .title {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container.games .game-card .title h6 {
  font-weight: bolder;
  color: var(--black-pearl);
  width: 150px;
  z-index: 5;
  transition: all 400ms;
  font-weight: 900;
  font-size: 20px;
}

.playstaion__button {
  width: 100px;
  height: 43px;
  display: flex;
  margin-inline: auto;
  justify-content: center;
  align-items: center;
}

.image__wrapper {
  padding-block: 15px;
  width: fit-content;
  margin-inline: auto;
}

.submitted__icon {
  position: absolute;
  bottom: 0px;
  right: -5px;
  font-size: 45px;
  color: var(--tealish-green);
}

.container.games .game-card.submitted:hover {
  cursor: default;
}

.container.games .game-card.submitted .title h6 {
  color: var(--white);
}

.container.games .game-card.submitted img {
  opacity: 0.5;
  position: relative;
}

.modal.show .modal-dialog {
  margin-top: 8%;
}

@media (max-width:576px) {
  .games .main-description .user-points {
    top:-5.5rem;
    right: 50%;
    translate:50% 0% ;
  }
  
  .games .main-description h4 {
    margin-block-start: 5rem;
  }
}