.success-conatiner {
  max-width: 90%;
  min-width: 50%;
  margin: auto;
  background: url(".././../../assets/games/success_view@2x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.success-conatiner span {
  color: var(--pinkish-red);
  font-size: clamp(2vw,2vw,40px);
  text-align: center;
  padding-right: 2%;
}

.success-conatiner span:first-child {
  padding-top: 15vw ;
}

.success-conatiner span:last-child {
  padding-bottom: 15vw;
}

