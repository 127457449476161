/* *************************************** */
/* *****  START FOOTER STYLE************** */
/* *************************************** */
.pages .link {
  border-top: 1px solid #fff;
}
.footer-overlay{
  position: absolute;
  background-color: rgba(0, 0, 0, .7);
  top: 0;
  right: 0;
  width: 100%;
  height: 101%;
}
.tabs li:after {
  content: "";
  position: absolute;
  min-height: 12px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  display: block;
  top: 8px;
  right: -18px;
}
.tabs li:last-of-type:after {
  content: unset;
}
.footer {
  color: #ccc;
  padding: 50pt 0 40pt;
}
.footer .logo img {
  width: 230px;
  height: 230px;
  object-fit: cover;
  margin-right: 25px;
  border-radius: 20px;
}
.space a {
  padding-right: 2%;
  color: var(--white);
}
.space a:hover {
  color: var(--red) !important;
}

.footer p {
  color: var(--color-primary-dark);
}
.row-centered {
  text-align: center;
  z-index: 2;
}
.green {
  color: var(--color-primary-dark);
}

@media (max-width: 992px) {
  body .footer {
    padding: 20pt 0 0;
  }
  body .footer .logo img {
    margin-right: 15px
  }
}
/* *************************************** */
/* *****  END FOOTER STYLE************** */
/* *************************************** */
