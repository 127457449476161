.live-match-lineup {
    position: relative;
    width: 100%;
    height: 100%;
}

.live-match-side-lineup-mini {
    margin-top: 20px;
}

.live-match-lineup-field {
    background-image: url('../../assets/bigField.jpg');
    background-size: 100% 100%;
    height: 620px;
    width: 100%;
    background-repeat: no-repeat;
}

.live-match-lineup-field-mini {
    position: relative;
    height: 450px;
    width: 100%;
    background-image: url('../../assets/smallField.png');
    background-size: 100% 100%;

}

.mini-player-image {
    background-color: white;
    border-radius: 50%;
    height: 40px;

}

.sideline-player-tshirt-num {

    position: absolute;
    right: 20px;
    top: 15px;
    color: var(--black);
}

@keyframes spin {
    from {
        transform: rotatex(45deg)rotatez(0);


    }

    to {

        transform: rotatex(45deg)rotatez(180deg);
    }
}