.riddle {
    border: 2px solid var(--tealish-green);
    margin: 15px;
    padding: 10px 10px 15px 10px !important;
    border-radius: 10px;
}

.riddle .choice {
    background-color: var(--hamilton-white);
    border: 0;
    box-shadow: none;
    border-radius: 2px;
    min-width: 200px;
    padding: 5px;
    margin: 5px;
}

.riddle .choice.selected {
    background-color: var(--tealish-green);
    color: var(--white);
}

.riddle .send {
    background-color: var(--tealish-green);
    color: var(--white);
    border: 0;
    box-shadow: none;
    border-radius: 5px;
    min-width: 100px;
    padding: 10px;
    margin: 5px;
    font-size: 15px;
}
