.gender-tabs {
    margin-block: 20px 30px;
}

.clubs.players .nav.nav-tabs {
    background-color: transparent !important;
}

.gender-tabs.nav-tabs .nav-link {
    border: 1px solid white !important;
    border-radius: 0 5px 5px 0 !important;
    background-color: none !important;
}

.gender-tabs.nav-tabs .nav-link:first-child {
    border-left: none !important;
    border-radius: 0 5px 5px 0 !important;
}

.gender-tabs.nav-tabs .nav-link:nth-child(2) {
    border-right: none !important;
    border-radius: 5px 0 0 5px !important;
}

.gender-tabs.nav-tabs .nav-link.active {
    background-color: var(--red) !important;
    color: white !important;
}

.gender-tabs.nav-tabs .nav-link:hover {
    color: white !important;
}

.gender-tabs.nav-tabs .nav-link span {
    padding-inline: 2rem;
}

.clubs.players .et-card {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 15px;
    padding: 1rem;
    color: #fff;
}

.clubs.players .et-card .team {
    flex-grow: 1;
}

.clubs.players .et-card .team img {
    outline: 2px solid var(--red);
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.clubs.players .et-card .flag img {
    outline: 2px solid var(--red);
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.clubs.players .et-card .flag {
    align-self: flex-start;
}

@media (max-width: 767.98px) {
    .clubs.players .et-card {
        justify-content: space-between;
    }

    .clubs.players .et-card .team {
        flex-grow: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .clubs.players .et-card .team img {
        outline: 2px solid var(--red);
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }

    .clubs.players .et-card .team>div {
        flex-grow: 1;
    }

    .clubs.players .et-card .flag {
        display: flex;
        justify-content: flex-end;
    }

    .clubs.players .et-card .flag img {
        width: 35px;
        height: 35px;
    }

    .clubs.players .et-card .flag {
        flex-grow: 1;
        align-self: flex-start;
        align-self: center;
    }
}