.where-is-the-ball .modal__bodyTitle img {
    border-radius: 17px;
    width: 100%;
    max-width: 600px;
    max-height: 450px;
    object-fit: contain;
}

.where-is-the-ball .modal__bodyTitle h3 {
    font-weight: 700;
    font-size: 20px;
}

.where-is-the-ball .modal__bodyTitle .position-btn{
    border: none;
    padding:5px 3rem ;
    margin-inline:5px ;
    border-radius: 5px;
    color: var(--regal-blue);
    font-size: 24px;
    font-weight: 700;
    transition: all 300ms;
}

.where-is-the-ball .modal__footer button:hover,
.where-is-the-ball .modal__footer button:active,
.where-is-the-ball .modal__bodyTitle .position-btn:hover,
.where-is-the-ball .modal__bodyTitle .position-btn:active {
    background-color: var(--tealish-green) !important;
    color: var(--white) !important;
}

.where-is-the-ball .modal__bodyTitle .selected {
    background-color: var(--tealish-green-dark);
    color: var(--white);
}