.modal-backdrop.show {
    opacity: .5;
    width: 100%;
    height: 100%;
}

.modal-content {
    background-color: var(--charcoal);
    color: var(--medium-grey);
    width: 130%
}

.playstation__modal .modal-content {
    background-color: #1E040A;
}

.world__cupPopup .modal-content {
    background-color: #3D0915;
    padding-block-end: 20px;
}

.worldCup-styles .modal-content.modal__content {
    background-color: #5e1224;
    border: 2px solid #ad8790;
}

.modal-header {
    border: none;
    color: white;
}

.comparison-modal .modal-header {
    padding: 0;
}

.world__cupPopup .modal-header .close:hover {
    color: unset;
}

.world__cupPopup .modal-header .close>span:first-of-type {
    font-size: 35px;
}

.modal-header button {
    color: white;
}

.show-grid.modal-body {
    color: #fff;
}

.progress {
    border-radius: 20px;
    box-shadow: none;
    min-width: 75px;
    height: 15px;
    line-height: 1.42857143;
    background-image: linear-gradient(to bottom, #ebebeb 0, #f5f5f5 100%);
    background-repeat: repeat-x;
}

.progress-bar {
    padding-inline: 5px;
    background-color: var(--red);
    align-items: center;
    width: 100%;
    min-width: 10px;
    padding-top: 3px;
}

.comparison-search-item {
    position: relative;
}

.comparison-search-item img:nth-of-type(1) {
    border-radius: 50%;
    width: 50px;
    height: 40px;
}

.comparison-search-item img:nth-of-type(2) {
    position: absolute;
    right: 50%;
    top: 40%;
    width: 30px;
}

.comparison-item {
    cursor: pointer;
}

.comparison-item-active {
    background-color: var(--black);
}

.items-stats-comparison {
    overflow-y: scroll;
    height: 410px;
}

.items-stats-comparison::-webkit-scrollbar {
    width: .5em;
}

.items-stats-comparison::-webkit-scrollbar-track {
    background-color: var(--charcoal);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.items-stats-comparison::-webkit-scrollbar-thumb {
    background-color: var(--medium-grey);
    outline: 1px solid slategrey;
}

.comparsion-logo {
    margin-top: 20px;
    border-radius: 10px;
    width: 11rem;
    transform: translateX(50%);
    margin-right: 50%;
}

.modal__dialog {
    display: flex;
}

.modal__content {
    height: calc(100% - 63px);
}

.modal__header button>span {
    font-size: 35px;
    color: white;
}

.modal__bodyTitle {
    margin-block-end: 25px;
}

.modal__bodyDescription {
    margin-block-end: 135px;
}

.modal__bodyGame--question {
    column-gap: 50px;
    padding-block: 20px;
}

.team__countryLogo>img {
    width: 100px;
    height: 100px;
    outline: 5px solid white;
    border-radius: 50%;
    background-color: white;
}

.team__countryLogo>div {
    margin-block-start: 50px;
}

.modal__gameIcon {
    color: #f4f4f4;
    width: 40px;
    height: 40px;
    margin-block-end: 60px;
    transform: rotate(180deg);
}

.modal__bodyGame--question .full-search-input {
    width: 200px;
    padding: 10px 15px;
    margin-inline: 0 !important;
}

.modal__bodyGame--question .full-search-menu {
    width: 200px;
}

.modal__footer,
.quizzes__formButton--wrapper {
    margin-block: 40px 15px;
    border: none !important;
}

.modal__footer>button,
.quizzes__formButton--wrapper>button {
    border-radius: 6px;
    padding: 10px 50px;
    background-color: white;
    color: var(--black) !important;
}

body:lang(en) .modal__gameIcon {
    transform: rotate(0deg);
}

.modal__content .btn-primary:hover,
.modal__content .btn-primary:disabled,
.modal__content .btn-primary:focus {
    background-color: white;
}

.error-msg {
    display: block;
    color: rgb(215, 42, 42);
}

.player__ofCompetition .modal__bodyDescription {
    margin-block-end: 50px;
}

.modal__bodyImage {
    background: url("../games/playstation.jpg");
    background-repeat: no-repeat;
    background-position: center 57%;
    margin-inline: auto;
    height: 180px;
    border-radius: 6px;
    position: relative;
    margin-block-end: 40px;
}

.match__dateDetails--overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, .13);
}

.match__dateDetails {
    background-color: white;
    padding: 10px 25px;
    color: var(--black);
    border-radius: 6px;
    width: fit-content;
    margin-inline: auto;
    margin-block-start: 20px;
}

.modal__bodyPlayer--details {
    margin-inline: auto;
    margin-block-end: 30px;
}

.playstation__playerOne--username,
.playstation__playerTwo--username {
    column-gap: 15px;
    cursor: pointer;
}

.playstation__playerOne--username img,
.playstation__playerTwo--username img {
    width: 45px;
    height: 45px;
}

.playstation__modal .modal__bodyDescription {
    margin-inline: auto;
    margin-block-end: 20px;
}

.playstation__detailsForm {
    column-gap: 15px;
}

.playstation__matchResult--image {
    margin-block-end: 10px;
}

.match__imageInput {
    display: none;
}

.match__imageInput+label {
    background-color: white;
    padding: 10px 25px;
    border-radius: 5px;
    width: fit-content;
    color: #5A0D1E;
    font-size: 20px;
    column-gap: 25px;
    margin-inline: auto;
    cursor: pointer;
}

.match__imageInput.disabled+label {
    cursor: unset;
    opacity: .7;
}

.playstation__detailsForm button {
    background-color: white;
    padding: 10px 25px;
    border-radius: 5px;
    font-size: 20px;
    border: none;
    height: 50px;
    color: #5A0D1E;
}

.playstation__detailsForm button:disabled {
    opacity: .7;
}

.playstation__TopTen--Header {
    padding: 5px;
    border-bottom: 2px solid white;
}

.playstation__TopTen--Header h6 {
    margin-bottom: 20px;
}

.playstation__TopTen--TableRow .playstation__TopTen--coreTitle,
.playstation__TopTen--TableRow .playstation__TopTen--playerTitleS {
    color: rgb(188, 168, 168)
}

.playstation__TopTen--Score {
    word-wrap: none;
}

.quizzes__modalButton--start {
    padding: 8px 50px;
    border-radius: 6px;
    border: none;
    margin-block-end: 20px;
}

.quiz__questionTitle {
    background-color: white;
    padding-block: 25px;
    color: #999999;
    border-radius: 6px;
}

.quizzes__modal .modal__bodyDescription {
    margin-block-end: 50px;
}

.quiz__choices {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    padding-inline: 25px;
    gap: 30px;

}

.quiz__choices>div {
    background-color: white;
    color: #999999;
    border-radius: 6px;
    padding: 10px;
}

.quiz__choices .input-group {
    justify-content: center;
    cursor: pointer;
}

.quiz__choices .input-group>label {
    margin-bottom: 0;
    cursor: pointer;
    width: 100%;
}

.quiz__choices .input-group-text,
.input__quizRadio {
    display: none;
}

.quiz__choices .input-group:has(input[type="radio"]:checked) {
    background-color: var(--red);
    color: white;
}

.quizzes__modal .quizzes__formButton--wrapper {
    margin-block: 50px 20px;
}

.quizzes__formButton--wrapper .form__button {
    display: none;
}

.quizzes__formButton--wrapper .form__button.active__button {
    display: block;
    border: none;
}

.timer__wrapper {
    background-color: white;
    color: #5a0d1e;
    width: fit-content;
    height: 37px;
    padding: 5px 50px;
    border-radius: 6px;
}

.timer__wrapper .quizzes__modalButton--start {
    margin-bottom: 0;
}

.timer__wrapper:has(.spinner-border) {
    margin-bottom: 15px;
}

.timer__wrapper:has(.quizzes__modalButton--start) {
    background-color: transparent !important;
}

.SoccerField {
    position: relative;
    margin-block-start: 50px;
}

.SoccerField>figure img {
    width: 100%;
    height: 500px;
}

.players__positionContainer {
    max-width: 90%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.players__positionContainer>div {
    padding-block: 60px;
    flex-wrap: nowrap;
}

.players__positionContainer>div:nth-of-type(1) {
    padding-block-start: 30px;
    padding-block-end: 30px;
}

.players__positionContainer>div:nth-of-type(2) {
    padding-block-start: 5px;
    padding-block-end: 40px;
}

.players__positionContainer>div:nth-of-type(3) {
    padding-block-start: 5px;
    padding-block-end: 20px;
}

.players__positionContainer>div:nth-of-type(4) {
    padding-block-start: 30px;
    padding-block-end: 5px;
}

.players__positionContainer>div:last-of-type {
    padding-block-start: 40px;
}

.players__positionContainer>div>div {
    text-align: center;
}

.TeamOfTheCompetition .player__image,
.SoccerField button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: none;
}

.TeamOfTheCompetition .modal__footer {
    margin-block: 0 50px;
}

.TeamOfTheCompetition .palyer__positionButton {
    padding-inline: 0;
}

.tactics.row .col {
    padding-inline: 5px;
}

.TeamOfTheCompetition .tactics>div button {
    padding-inline: 0;
    padding-block: 10px;
    width: 100%;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    background-color: rgba(30, 4, 10, 0.6);
    color: white;
}

.TeamOfTheCompetition .tactics>div button.active {
    color: rgba(30, 4, 10, 0.6);
    background-color: white;
}

.select__playerModal--image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    outline: 2px solid white;
    background-color: white;
    margin-block: 50px 30px;
}

.select__playerModal .full-search {
    justify-content: center;
}

.select__playerModal input {
    margin-inline: 0 !important;
}

.select__playerModal input+ul {
    width: 300px !important;
}

.select__playerModal .modal__footer {
    margin-block: 70px 30px;
}

.user__phoneNumber--modal button {
    padding-block: 5px;
    border-radius: 6px;
}

.user__phoneNumber--modal form {
    width: 50% !important;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-block-end: 50px;
}

.H2H-modal-team img {
    width: 60px;
    height: 60px;
}

@media (max-width: 1023.98px) {
    .TeamOfTheCompetition .modal-body h3 {
        font-size: var(--font-size-md);
    }

    .TeamOfTheCompetition .modal-body p {
        font-size: var(--font-size-xm);
        text-align: justify;
    }

    .TeamOfTheCompetition .tactics {
        flex-wrap: nowrap;
        width: 100%;
        margin-inline: auto;
    }

    .TeamOfTheCompetition .tactics>div {
        padding-inline: 5px;
    }

    .TeamOfTheCompetition .tactics>div button {
        font-size: var(--font-size-sm);
    }

    .SoccerField {
        margin-block-start: 30px;
        padding-inline: 0 !important;
    }

    .SoccerField>figure img {
        width: 100%;
        height: 500px;
    }

    .players__positionContainer>div {
        padding-block: 40px;
    }

    .players__positionContainer>div:nth-of-type(1) {
        padding-block-start: 30px;
    }

    .players__positionContainer>div:nth-of-type(2) {
        padding-block-start: 30px;
        padding-block-end: 20px;
    }

    .players__positionContainer>div:nth-of-type(3) {
        padding-block-start: 50px;
        padding-block-end: 30px;
    }

    .players__positionContainer>div:nth-of-type(4) {
        padding-block-start: 10px;
        padding-block-end: 0px;
    }

    .TeamOfTheCompetition .player__image,
    .SoccerField button {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: none;
    }

    .TeamOfTheCompetition .modal__footer {
        margin-block: 0 40px;
    }

    .TeamOfTheCompetition .modal__footer>button {
        padding: 7px 35px;
    }
}

@media (max-width: 475.98px) {

    .TeamOfTheCompetition .modal-body,
    .TeamOfTheCompetition .modal-body>div,
    .players__positionContainer>div>div {
        padding-inline: 5px;
    }

    .TeamOfTheCompetition .modal-body h3 {
        font-size: var(--font-size-xxm);
    }

    .TeamOfTheCompetition .modal-body p {
        font-size: var(--font-size-md);
    }

    .TeamOfTheCompetition .tactics>div {
        padding-inline: 3px;
    }

    .TeamOfTheCompetition .tactics>div button {
        font-size: var(--font-size-xs);
    }

    .players__positionContainer>div {
        padding-block: 30px;
        padding-inline: 5px !important;
    }

    .players__positionContainer>div:nth-of-type(1) {
        padding-block-start: 35px;
        padding-block-end: 10px;
    }

    .players__positionContainer>div:nth-of-type(2) {
        padding-block-start: 10px;
        padding-block-end: 10px;
    }

    .players__positionContainer>div:nth-of-type(3) {
        padding-block-start: 10px;
        padding-block-end: 10px;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        margin-right: 0px;
    }

    .players__positionContainer>div:last-of-type {
        padding-block-start: 20px;
    }

    .SoccerField>figure img {
        width: 100%;
        height: 300px;
    }

    .TeamOfTheCompetition .player__image,
    .SoccerField button {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: none;
    }

    .TeamOfTheCompetition .modal__footer {
        margin-block: 0 30px;
    }

    .TeamOfTheCompetition .modal__footer>button {
        padding: 5px 30px;
    }
}

@media (max-width:1199.98px) {
    form {
        width: 100%;
    }

    .quiz__questionTitle {
        padding-block: 15px;
    }

    .quiz__choices {
        grid-template-columns: 1fr;
        column-gap: 0;
    }

}

@media (max-width:991.98px) {
    .playstation__modal .modal-body {
        padding-inline: 0;
    }

    .modal__bodyImage {
        background-size: cover;
    }

    .match__dateDetails {
        padding: 5px 12px;
    }

    .modal__bodyPlayer--details {
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }

    .modal__bodyPlayer--details img {
        width: 55px;
        height: 55px;
    }

    .playstation__playerOne--username,
    .playstation__playerTwo--username {
        font-size: var(--font-size-md);
        column-gap: 5px;
    }

    .match__imageInput+label {
        padding: 7px 15px;
        width: fit-content;
        font-size: var(--font-size-xm);
        column-gap: 15px;
    }

    .playstation__detailsForm {
        flex-direction: column !important;
    }

    .playstation__detailsForm button {
        height: 41px;
        width: fit-content;
        margin-inline: auto;
    }

    .playstation__competitorsInfo {
        row-gap: 15px;
    }

}

@media (max-width:767.98px) {
    .playstation__competitorsInfo>div {
        justify-content: center !important;
    }

    .comparsion-logo {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .modal__bodyDescription {
        font-size: var(--font-size-sm);
        margin-block-end: 50px;
    }

    .modal__bodyGame--question {
        flex-direction: column !important;
        row-gap: 20px;
        padding-block: 10px;
    }

    .team__countryLogo>img {
        width: 60px;
        height: 60px;
        outline: 5px solid white;
    }

    .team__countryLogo>div {
        margin-block-start: 25px;
        font-size: var(--font-size-sm);
    }

    body:lang(en) .modal__gameIcon,
    .modal__gameIcon {
        width: 25px;
        height: 25px;
        transform: rotate(90deg);
        margin-block-end: 0;
    }

    body:lang(en) .modal__gameIcon:last-of-type,
    .modal__gameIcon:last-of-type {
        transform: rotate(0deg);
    }

    .modal__footer {
        margin-block: 60px 50px;
    }
}

@media (max-width:600px) {
    .comparison-modal input {
        height: 30px;
    }

    .comparison-search-item img:nth-of-type(1) {
        border-radius: 50%;
        width: 35px;
        height: 35px;
    }

    .comparison-search-item img:nth-of-type(2) {
        position: absolute;
        right: 50%;
        top: 40%;
        width: 20px;
    }

    .items-stats-comparison {
        overflow-y: scroll;
        height: 270px;
    }
}

@media (max-width:900px) {
    .modal-content {
        background-color: var(--charcoal);
        color: var(--medium-grey);
        width: 100%
    }
}