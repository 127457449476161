.slick-slide.slick-active:hover {
  z-index: 1;
}
.tournament-carousel-card {
  height: 140px !important;
  width: 100% !important;
  background-color: var(--charcoal);
}

.tournament-carousel-component .slick-list .slick-track {
  background: var(--charcoal);
  align-items: flex-start;
}

.tournament-carousel-card img {
  width: 50px;
  height: 40px;
  object-fit: contain;
}

.tournament-carousel-card.dummy-card{
  display: none !important;
}

.loader {
  background-color: var(--charcoal);
}