.tournament-statistics.table-borderless th {
    padding: 17px ;
    vertical-align: top;
    border-top:none !important ;
}

@media (max-width:500px) {
    .tournament-statistics.table-borderless img{
        width: 20px;
    }
    .tournament-statistics.table-borderless td, .tournament-standing.table-borderless th {
        padding: 5px ;
        vertical-align: top;
        border-top:none !important ;
    }

}