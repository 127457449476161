.choose-method img{
    width: 180px;
}
.choose-method button{
    border:none;
    padding: 5px 45px;
    background-color: var(--white);
    border-radius: 10px;
}
.choose-method div img{
    width: 130px;
    height: 50px;
}