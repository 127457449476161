.home-page-calendar {
    position: relative;
}

.home-page-calendar-title{
    position: absolute;
    top:-40px;
    left: 0;
    z-index: 1;
}

.event-banner img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
    cursor: pointer;
}

@media (max-width:425px) {
    .home-page-calendar-title{
        top:-15px;
    }
}