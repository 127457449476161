.melouk-header nav {
    height: 48px;
}

.melouk-header .navbar {
    background: var(--hamilton-white) !important;
    overflow-x: auto;
    overflow-y: hidden;
}

.melouk-header .navbar.navbar-dark ul.navbar-nav a.nav-link {
    color: var(--charcoal);
    white-space: nowrap;
}

.melouk-header .navbar.navbar-dark .navbar-collapse {
    justify-content: center;
}
